import {GitLabVariablesSchema} from "./GitLabVariables";
import {z} from "zod";
import {ValidationError} from "../../../types/enums/ValidationError";

export const CreateManagedAppVariablesRequestSchema = z.discriminatedUnion("name", [
    GitLabVariablesSchema
])

export const CreateManagedAppRequestSchema = z.object({
    customerId: z.string(),
    subdomain: z.string().optional().or(
        z.string().regex(new RegExp("^[a-z0-9]([a-z0-9-]*[a-z0-9])?$")).min(0).max(16)
    ),
    cpu: z.number().min(2, `${ValidationError.Min}2`).max(32, `${ValidationError.Max}32`),
    memory: z.number().min(4, `${ValidationError.Min}4`).max(64, `${ValidationError.Max}32`),
    storage: z.number().min(25, `${ValidationError.Min}25`).max(5000, `${ValidationError.Max}5000`),
}).and(CreateManagedAppVariablesRequestSchema)

export type CreateManagedAppRequest = z.infer<typeof CreateManagedAppRequestSchema>