import {useEffect, useRef} from "react";
import {useUserContext} from "../../../../contexts/UserContext";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../../stores/redux/hooks/useAppDispatch";
import {authenticationError} from "../../slices/authSlice";
import AuthOverlay from "../../../../components/AuthOverlay/AuthOverlay";

const OAuthRedirect = () => {
    const callbackRan = useRef(false)
    const navigate = useNavigate()
    const {signInCallback} = useUserContext()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!callbackRan.current) {
            signInCallback()
                .then(_ => navigate("/"))
                .catch(_ => {
                    dispatch(authenticationError())
                })
        }

        return () => {
            callbackRan.current = true
        }
    }, [signInCallback, navigate, dispatch])

    return (
        <AuthOverlay/>
    )
}

export default OAuthRedirect