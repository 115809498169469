import {api} from "../../../stores/redux/reducers/api";
import {UpdateCustomerRequest} from "../models/UpdateCustomerRequest";
import {Customer} from "../models/Customer";

export const customersApi = api.injectEndpoints({
    endpoints: build => ({
        getCustomer: build.query<Customer, string>({
            query: (id) => `customers/${id}`,
            providesTags: [{type: "Customer"}] 
        }),
        startBankVerification: build.mutation<{ paymentUrl: string }, { id: string }>({
            query: ({id}) => ({
                url: `customers/${id}/verify-bank`,
                method: "GET"
            })
        }),
        updateCustomer: build.mutation<{}, UpdateCustomerRequest & { id: string }>({
            query: (data) => {
                let body = data as UpdateCustomerRequest;
                
                if (body.useSameInvoiceAddress) {
                    body.invoiceAddress = body.address
                }
                
                return ({
                    url: `/customers/${data.id}`,
                    method: "PUT",
                    body: data as UpdateCustomerRequest
                })
            },
            invalidatesTags: [{type: "Customer"}]
        })
    })
})

export const {useStartBankVerificationMutation, useUpdateCustomerMutation, useGetCustomerQuery} = customersApi