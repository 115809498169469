import {createContext, FC, PropsWithChildren, useContext, useState} from "react";

interface IAppContainerContext {
    title: string
    setTitle: (title: string) => void
    showBackButton: boolean
    setShowBackButton: (showBackButton: boolean) => void
}

export const AppContainerContext = createContext({} as IAppContainerContext)

export const AppContainerContextProvider: FC<PropsWithChildren> = ({children}) => {
    const [title, setTitle] = useState<string>("")
    const [showBackButton, setShowBackButton] = useState<boolean>(false)
    
    return (
        <AppContainerContext.Provider value={{
            title, 
            setTitle,
            showBackButton,
            setShowBackButton
        }}>
            {children}
        </AppContainerContext.Provider>
    )
}

export const useAppContainer = () => useContext(AppContainerContext)