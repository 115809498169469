import {api} from "../../../stores/redux/reducers/api";
import {Vdc} from "../models/Vdc";
import {Paginated} from "../../../types/models/Paginated";
import {CreateVdcRequest} from "../models/CreateVdcRequest";
import {liveHandler} from "../../Live/models/LiveHandler";
import {ProductStatus} from "../models/ProductStatus";

const vdcsApi = api.injectEndpoints({
    endpoints: build => ({
        getPaginatedVdcs: build.query<Paginated<Vdc>, { customerId: string, page: number, pageSize: number }>({
            query: ({customerId, page, pageSize}) => `customers/${customerId}/vdcs?page=${page}&pageSize=${pageSize}`,
            providesTags: (result, _error, _args) =>
                result ? [
                    ...result.items.map(({id}) => ({type: "Vdcs" as const, id})),
                    {type: "Vdcs", id: "PAGINATED"}
                ] : [{
                    type: 'Vdcs',
                    id: "PAGINATED"
                }]
        }),
        getVdc: build.query<Vdc, { customerId: string, vdcId: string }>({
            query: ({customerId, vdcId}) => `customers/${customerId}/vdcs/${vdcId}`,
            providesTags: (_result, error, {vdcId}) => error ? [] : [{type: "Vdcs", id: vdcId}]
        }),
        createVdc: build.mutation<Vdc, { customerId: string } & CreateVdcRequest>({
            query: ({customerId, ...data}) => ({
                url: `/customers/${customerId}/vdcs`,
                method: "POST",
                body: data
            }),
            invalidatesTags: (data) => data ? [{type: "Vdcs", id: data.id}] : []
        }),
        updateVdc: build.mutation<Vdc, { customerId: string, id: string } & CreateVdcRequest>({
            query: (data) => ({
                url: `/customers/${data.customerId}/vdcs/${data.id}`,
                method: "PUT",
                body: data as CreateVdcRequest
            }),
            invalidatesTags: (data) => data ? [{type: "Vdcs", id: data.id}] : []
        })
    })
})

liveHandler.addListener(
    "vdcs/:id",
    (dispatch, method, {id}, body) => {
        if (method === "updated") {
            let vdc = JSON.parse(body!) as Vdc

            if (vdc.status === ProductStatus.Ready) {
                dispatch(api.util.invalidateTags([{type: "Vdcs", id: id}]))
            }
        }

        if (method === "created") {
            dispatch(api.util.invalidateTags([{type: "Vdcs", id: "PAGINATED"}]))
        }

        if (method === "deleted") {
            dispatch(api.util.invalidateTags([{type: "Vdcs", id: "PAGINATED"}]))
        }
    }
)

export const {useGetPaginatedVdcsQuery, useGetVdcQuery, useCreateVdcMutation, useUpdateVdcMutation} = vdcsApi