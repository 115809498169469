import {z} from 'zod'
import {CustomerTypes} from "../constants/CustomerTypes";
import {ValidationError} from "../../../types/enums/ValidationError";
import {UpdateAddressRequest} from "./UpdateAddressRequest";

export const UpdateCustomerRequestSchema = z.object({
    id: z.string(),
    type: z.string().refine(v => CustomerTypes.All.includes(v.toLowerCase()), "You have not selected a type"),
    name: z.string().min(1, `${ValidationError.CharMin}2`).max(30, `${ValidationError.Max}30`),
    phoneNumber: z.string().min(4, `${ValidationError.CharMin}4`).max(30, `${ValidationError.Max}20`),
    address: z.object(UpdateAddressRequest.shape),
    useSameInvoiceAddress: z.boolean(),
    invoiceAddress: z.object(UpdateAddressRequest.shape).optional()
})

export type UpdateCustomerRequest = z.infer<typeof UpdateCustomerRequestSchema>