import {api} from "../../../stores/redux/reducers/api";
import {Paginated} from "../../../types/models/Paginated";
import {Registrant} from "../models/Registrant";
import {GetRegistrantsByCustomerId} from "../models/GetRegistrantsByCustomerId";
import {CreateRegistrantSchema} from "../models/CreateRegistrantSchema";
import {UpdateRegistrantSchema} from "../models/UpdateRegistrantSchema";

const registrantsApi = api.injectEndpoints({
    endpoints: build => ({
        getRegistrantsByCustomerId: build.query<Paginated<Registrant>, GetRegistrantsByCustomerId>({
            query: (
                {
                    customerId,
                    page,
                    pageSize
                }
            ) => `customers/${customerId}/registrants?page=${page}&pageSize=${pageSize}`,
            providesTags: [{type: "Registrant"}]
        }),
        getRegistrant: build.query<Registrant, { customerId: string, registrantId: string }>({
            query: ({customerId, registrantId}) => `customers/${customerId}/registrants/${registrantId}`,
            providesTags: (response) => [{type: "Registrant", id: response?.id}]
        }),
        createRegistrant: build.mutation<Registrant, CreateRegistrantSchema>({
            query: (data) => ({
                url: `customers/${data.customerId}/registrants`,
                method: "POST",
                body: data
            }),
            invalidatesTags: [{type: "Registrant"}]
        }),
        updateRegistrant: build.mutation<Registrant, UpdateRegistrantSchema>({
            query: (data) => ({
                url: `customers/${data.customerId}/registrants/${data.id}`,
                method: "PUT",
                body: data
            }),
            invalidatesTags: (response) => [{type: "Registrant"}, {type: "Registrant", id: response?.id}]
        }),
        deleteRegistrant: build.mutation<Registrant, { customerId: string, id: string }>({
            query: (data) => ({
                url: `customers/${data.customerId}/registrants/${data.id}`,
                method: "DELETE"
            }),
            invalidatesTags: (response) => [{type: "Registrant"}, {type: "Registrant", id: response?.id}]
        })
    })
})

export const {
    useGetRegistrantsByCustomerIdQuery,
    useCreateRegistrantMutation,
    useGetRegistrantQuery,
    useUpdateRegistrantMutation,
    useDeleteRegistrantMutation
} = registrantsApi