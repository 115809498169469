import {api} from "../../../stores/redux/reducers/api";
import {Invoice} from "../models/Invoice";
import {Paginated} from "../../../types/models/Paginated";

const invoicesApi = api.injectEndpoints({
    endpoints: build => ({
        getCustomerInvoicesPaginated: build.query<Paginated<Invoice>, { customerId: string, page: number, pageSize: number }>({
            query: ({customerId, page, pageSize}) => `customers/${customerId}/invoices?page=${page}&pageSize=${pageSize}`
        }),
        getCustomerInvoice: build.query<Invoice, {customerId: string, id: string}>({
            query: ({customerId, id: invoiceId}) => `customers/${customerId}/invoices/${invoiceId}`
        })
    })
})

export const { useLazyGetCustomerInvoicesPaginatedQuery, useGetCustomerInvoicesPaginatedQuery, useGetCustomerInvoiceQuery } = invoicesApi