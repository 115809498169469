import {FieldValues, get, Path, PathValue, useFormContext} from "react-hook-form";
import {FormInputProps} from "../Form";
import {ChangeEvent, ReactNode, useState} from "react";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import OutlinedTextFieldMenu from "../../TextFields/OutlinedTextFieldMenu/OutlinedTextFieldMenu";

interface IProps<TSchema extends FieldValues> {
    label: string
    name: string
    isLoading: boolean
    className?: string
    supportingText?: ReactNode
    registerOptions?: RegisterOptions
    options: { label: string, value: string | number }[]
}

function FormOutlinedTextFieldMenu<TSchema extends FieldValues>(
    {
        label,
        name,
        isLoading,
        className,
        supportingText,
        registerOptions,
        options
    }: IProps<TSchema>
) {
    const {register, getValues, formState, setValue} = useFormContext<TSchema>()

    const [val, setVal] = useState< string | number | readonly string[] | undefined>(getValues(name as Path<TSchema>))

    const {ref} = register(name as Path<TSchema>, registerOptions)

    const onChangeInternal = (e: ChangeEvent<HTMLSelectElement>) => {
        setValue(name as Path<TSchema>, e.target.value as PathValue<TSchema, Path<TSchema>>)
        setVal(e.target.value)
    }

    return (
        <OutlinedTextFieldMenu
            className={className}
            label={label}
            disabled={isLoading}
            errors={get(formState.errors, name) && get(formState.errors, name).message}
            supportingText={supportingText}
            value={val}
            ref={ref}
            onChange={onChangeInternal}
            options={options}
        />
    )
}

export default FormOutlinedTextFieldMenu