import {DetailedHTMLProps, forwardRef, InputHTMLAttributes, ReactNode} from "react";

import MaterialIcon from "../../MaterialIcon/MaterialIcon";
import {useTheme} from "../../../contexts/ThemeContext";
import {ValidationErrorTransformer} from "../../../utils/ValidationErrorTransformer";

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label: string
    errors?: string[] | string
    onIncrement?: () => void
    onDecrement?: () => void
    disabled?: boolean
    unit?: string
    supportingText?: ReactNode
}

const OutlinedTextField = forwardRef<HTMLInputElement, IProps>((
    {
        label,
        supportingText,
        unit,
        errors,
        className,
        disabled = false,
        onIncrement,
        onDecrement,
        ...props
    },
    ref
) => {

    const {getThemeClassName} = useTheme()

    const baseClassName = "outlined-text-field"

    const onDecrementInternal = () => {
        if (disabled || !onDecrement) return

        onDecrement()
    }

    const onIncrementInternal = () => {
        if (disabled || !onIncrement) return

        onIncrement()
    }

    return (
        <div
            className={`
                ${baseClassName}
                ${getThemeClassName(baseClassName)}
                ${baseClassName}--${props.type}
                ${disabled && baseClassName + "--disabled"} 
                ${(errors && errors.length > 0) && baseClassName + "--error"}
                ${className}
                `}>
            <div className={`${baseClassName}__input-container`}>
                <div className={`${baseClassName}__container`}>
                    <input className={`${baseClassName}__input`} placeholder=" " disabled={disabled}
                           ref={ref} {...props} />

                    <div className={`${baseClassName}__outline`}>
                        <div className={`${baseClassName}__label`}>
                            <p>{label}</p>
                        </div>
                    </div>

                    {unit && <div className={`${baseClassName}__unit`}><p>{unit}</p></div>}
                    {
                        props.type === "number" &&
                        <div className={`${baseClassName}__buttons`}>
                            <button className={`${baseClassName}__button`} onClick={onDecrementInternal}
                                    type={"button"}>
                                <MaterialIcon name={"remove"}/>
                            </button>
                            <button className={`${baseClassName}__button`} onClick={onIncrementInternal}
                                    type={"button"}>
                                <MaterialIcon name={"add"}/>
                            </button>
                        </div>
                    }
                </div>

                {
                    errors && (
                        Array.isArray(errors) ?
                            errors.map(e => (
                                <div className={`${baseClassName}__error-text`}
                                     key={props.name + e}>{ValidationErrorTransformer.transform(label, e)}</div>
                            ))
                            :
                            <div className={`${baseClassName}__error-text`}
                                 key={props.name}>{ValidationErrorTransformer.transform(label, errors)}</div>
                    )
                }
                <div className={`${baseClassName}__supporting-text`}>{supportingText}</div>
            </div>
        </div>
    )
})

export default OutlinedTextField