import {FC, useMemo} from "react";
import {useTheme} from "../../contexts/ThemeContext";

interface IProps {
    strokeWidth?: number
    loaded?: boolean
    error?: boolean
}

const Loader: FC<IProps> = ({loaded, error, strokeWidth = 20}) => {
    const {getThemeClassName} = useTheme()

    const baseClassName = "loader"

    const stopRotation = useMemo(() => {
        return loaded || error
    }, [loaded, error])

    return (
        <svg viewBox="0 0 400 400" className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <path
                fill="none"
                stroke="red"
                strokeWidth={`${strokeWidth}`}
                strokeDasharray="75 100"
                pathLength="100"
                strokeLinecap="round"
                d={`
                  M ${strokeWidth / 2}, 200
                  A 50 50 4 1 1 ${400 - strokeWidth / 2},200
                  A 50 50 4 1 1 ${strokeWidth / 2},200
                `}
            >
                {
                        <animate
                            attributeName="stroke-dasharray"
                            calcMode="linear"
                            values={stopRotation ? "75 100; 0 100; 100 100" : "75 100; 0 100; 75 100"}
                            dur={stopRotation ? "3s" : "3s"}
                            fill={stopRotation ? "freeze" : undefined}
                            repeatCount={stopRotation ? undefined : "indefinite"}
                        />
                }
            </path>


            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0"
                to="360"
                dur=".75s"
                repeatCount="indefinite"
            />
        </svg>
    )
}

export default Loader