import {DialogPortal} from "../../../../../hooks/DialogPortal";
import {FormProvider} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import FilledButton from "../../../../../components/Buttons/FilledButton/FilledButton";
import MaterialSymbol from "../../../../../components/MaterialSymbol/MaterialSymbol";
import {useAppForm} from "../../../../../hooks/useAppForm";
import {CreateRegistrantSchema} from "../../../models/CreateRegistrantSchema";
import {
    useCreateRegistrantMutation,
    useGetRegistrantQuery,
    useUpdateRegistrantMutation
} from "../../../redux/registrantsApi";
import {useAppSelector} from "../../../../../stores/redux/hooks/useSelector";
import {useNavigate, useParams} from "react-router-dom";
import TextButton from "../../../../../components/Buttons/TextButton/TextButton";
import FormOutlinedTextField from "../../../../../components/Form/components/FormOutlinedTextField";
import Typo from "../../../../../components/Typo/Typo";
import FormOutlinedTextFieldMenu from "../../../../../components/Form/components/FormOutlinedTextFieldMenu";
import iso3166 from "iso-3166-1-alpha-2";
import {UpdateRegistrantSchema} from "../../../models/UpdateRegistrantSchema";

const RegistrantsDialog: React.FC = () => {
    const baseClassName = "registrant-dialog"

    const [showDialog, setShowDialog] = useState<boolean>(false)

    const customerId = useAppSelector(state => state.auth.userInfo?.profile.customerId)

    const navigate = useNavigate()

    const {id} = useParams<{ id: string }>()

    const {data, isLoading: isGetting} = useGetRegistrantQuery({
        customerId: customerId ?? "",
        registrantId: id ?? ""
    }, {skip: !id || !customerId})

    const initData: CreateRegistrantSchema = useMemo(() => {
        return {
            customerId: customerId ?? null,
            id: id ?? null,
            alias: data?.alias ? data.alias : "",
            companyName: data?.companyName ? data.companyName : "",
            jobTitle: data?.jobTitle ? data.jobTitle : "",
            firstName: data?.firstName ? data.firstName : "",
            lastName: data?.lastName ? data.lastName : "",
            street: data?.street ? data.street : "",
            houseNumber: data?.houseNumber ? data.houseNumber : "",
            suffix: data?.suffix ? data.suffix : "",
            postalCode: data?.postalCode ? data.postalCode : "",
            city: data?.city ? data.city : "",
            state: data?.state ? data.state : "",
            phone: data?.phone ? data.phone : "",
            fax: data?.fax ? data.fax : "",
            email: data?.email ? data.email : "",
            country: data?.country ? data.country : ""
        }
    }, [customerId, data])

    const {
        form,
        formReset,
        onFormSubmit,
        isLoading,
        isSuccess,
        isError
    } = useAppForm(
        initData,
        id ? UpdateRegistrantSchema : CreateRegistrantSchema,
        id ? useUpdateRegistrantMutation : useCreateRegistrantMutation
    )

    const onClickStore = () => {
        setShowDialog(true)
    }

    const onCloseDialog = () => {
        formReset()
        setShowDialog(false)
    }

    useEffect(() => {
        console.log("aaa")

        if (isSuccess) {
            onCloseDialog()
            if (id == null && data) navigate(`/registrants/${data?.id}`)
        }
    }, [data, isSuccess])

    return (
        <>
            <div className={`${baseClassName}__create-button-container`}>
                <FilledButton textType={"icon-text"}
                              color={"blue"}
                              className={`${baseClassName}__create-button`}
                              onClick={onClickStore}>
                    <MaterialSymbol name={id ? "edit" : "add"}/> {id ? "Edit" : "Create"}
                </FilledButton>
            </div>

            <DialogPortal
                breakpoint={"medium"}
                type={"basic"}
                show={showDialog}
                className={`${baseClassName}`}
                headline={"Contact info"}
                onCloseClick={onCloseDialog}
                isLoading={isLoading}
                content={(
                    <FormProvider {...form}>
                        <form onSubmit={onFormSubmit}>
                            <div className={`${baseClassName}__form`}>
                                <Typo.Headline size={"small"} className={`${baseClassName}__title`}>
                                    Create registrant
                                </Typo.Headline>

                                {
                                    id === null &&
                                    <FormOutlinedTextField
                                        label={"Alias"}
                                        name={"alias"}
                                        isLoading={isLoading}
                                    />
                                }

                                <div className={`${baseClassName}__line`}>
                                    {
                                        id === null &&
                                        <FormOutlinedTextField
                                            label={"Company (optional)"}
                                            name={"companyName"}
                                            isLoading={isLoading}
                                        />
                                    }

                                    <FormOutlinedTextField
                                        label={"Job title (optional)"}
                                        name={"jobTitle"}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div className={`${baseClassName}__line`}>
                                    <FormOutlinedTextField
                                        label={"First name"}
                                        name={"firstName"}
                                        className={`${baseClassName}__first-name`}
                                        isLoading={isLoading}
                                    />

                                    <FormOutlinedTextField
                                        label={"Last name"}
                                        name={"lastName"}
                                        className={`${baseClassName}__last-name`}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div className={`${baseClassName}__line`}>
                                    <FormOutlinedTextField
                                        label={"Street"}
                                        name={"street"}
                                        className={`${baseClassName}__street`}
                                        isLoading={isLoading}
                                    />

                                    <FormOutlinedTextField
                                        label={"Number"}
                                        name={"houseNumber"}
                                        className={`${baseClassName}__house-number`}
                                        isLoading={isLoading}
                                    />

                                    <FormOutlinedTextField
                                        label={"Suffix"}
                                        name={"suffix"}
                                        className={`${baseClassName}__suffix`}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div className={`${baseClassName}__line`}>
                                    <FormOutlinedTextField
                                        label={"Postal code"}
                                        name={"postalCode"}
                                        className={`${baseClassName}__postal-code`}
                                        isLoading={isLoading}
                                    />

                                    <FormOutlinedTextField
                                        label={"City"}
                                        name={"city"}
                                        className={`${baseClassName}__city`}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <FormOutlinedTextField
                                    label={"State"}
                                    name={"state"}
                                    isLoading={isLoading}
                                />


                                <FormOutlinedTextFieldMenu
                                    label={"Country"}
                                    name={"country"}
                                    isLoading={isLoading}
                                    options={
                                        Object.entries(iso3166.getData()).map(([key, value]) => ({
                                            label: value ?? "",
                                            value: key
                                        }))
                                    }
                                />

                                <FormOutlinedTextField
                                    label={"Phone"}
                                    name={"phone"}
                                    isLoading={isLoading}
                                />

                                <FormOutlinedTextField
                                    label={"Fax (optional)"}
                                    name={"fax"}
                                    isLoading={isLoading}
                                />

                                <FormOutlinedTextField
                                    label={"Email"}
                                    name={"email"}
                                    isLoading={isLoading}
                                />

                                <div className={`${baseClassName}__buttons-container`}>
                                    <TextButton color={"blue"}
                                                type={"button"}
                                                textType={"text-only"}
                                                onClick={onCloseDialog}
                                    >
                                        Cancel
                                    </TextButton>

                                    <TextButton color={"blue"}
                                                textType={"text-only"}
                                    >
                                        {id ? "Save" : "Create"}
                                    </TextButton>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                )}
            />
        </>
    )
}

export default RegistrantsDialog;