import React, {FC, PropsWithChildren} from "react";
import {useTheme} from "../../../../contexts/ThemeContext";
import Account from "./Account/Account";
import Logo from "../../Logo/Logo";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";
import Settings from "./Settings/Settings";
import {useSideSheet} from "../../../../contexts/SideSheetContext";
import AccountButton from "./Account/AccountButton/AccountButton";
import MaterialSymbol from "../../../../components/MaterialSymbol/MaterialSymbol";
import {useSidebar} from "../../../../contexts/SidebarContext";
import {useAppContainer} from "../../../../contexts/AppContainerContext";
import {useNavigate} from "react-router-dom";
import {MediaQueries} from "../../../../utils/MediaQueries";
import Typo from "../../../../components/Typo/Typo";

interface IProps extends PropsWithChildren {
}

const AppHeader: FC<IProps> = ({children}) => {
    const {theme} = useTheme()

    const {setContent, key} = useSideSheet()
    const {toggleSidebar} = useSidebar()
    const {title, showBackButton} = useAppContainer()

    const navigate = useNavigate()

    const onClickSettings = () => setContent("settings", <Settings/>)
    const onAccountClick = () => setContent("account", <Account/>)
    const onButtonClick = () => {
        if (showBackButton) {
            navigate(-1)
        } else {
            toggleSidebar()
        }
    }

    const baseClassName = "app-header"

    return (
        <div className={`${baseClassName} ${baseClassName}--${theme}`}>
            <TextButton
                className={`${baseClassName}__menu-button`}
                color={"blue"} textType={"icon-only"}
                onClick={onButtonClick}
            >
                {
                    showBackButton
                        ? <MaterialSymbol name={"arrow_back"}/>
                        : <MaterialSymbol name={"menu"}/>
                }
            </TextButton>

            {MediaQueries.medium() && title &&
                <Typo.Title size={"medium"} className={`${baseClassName}__title`}>
                    {title}
                </Typo.Title>
            }

            <Logo/>

            <div className={`${baseClassName}__right`}>
                <TextButton
                    color={"grey"}
                    textType={"icon-only"}
                    onClick={onClickSettings}
                    className={`${baseClassName}__settings-button ${key === "settings" ? `${baseClassName}__settings-button--selected` : ""}`}
                >
                    <MaterialSymbol name={"settings"}/>
                </TextButton>

                <AccountButton showModal={onAccountClick}/>
            </div>

            {children}
        </div>
    )
}

export default AppHeader