import {FC, useMemo} from "react";
import Button, {ButtonProps} from "../Button";
import {useTheme} from "../../../contexts/ThemeContext";

interface IProps extends ButtonProps {
    color: "blue" | "red"
}

const FilledButton: FC<IProps> = (
    {
        children,
        textType,
        className,
        color,
        ...props
    }
) => {
    const {getThemeClassName} = useTheme()

    const fullClassName = useMemo(() => {
        let tmp = "filled-button";

        if (color) tmp += ` filled-button--${color}`
        if (textType) tmp += ` filled-button--${textType}`

        return tmp + " " + className;
    }, [textType, className, color])

    return (
        <Button
            {...props}
            textType={textType}
            className={`${fullClassName} ${getThemeClassName("filled-button")}`}
        >
            {children}
        </Button>
    )
}

export default FilledButton