import React, {FC, ReactNode, useMemo, useState} from "react";
import {DialogPortal} from "../../hooks/DialogPortal";
import Loader from "../Loader/Loader";
import TextButton from "../Buttons/TextButton/TextButton";
import {useTheme} from "../../contexts/ThemeContext";

interface IProps {
    className?: string
    button: (onClick: () => void) => ReactNode
    body: ReactNode
    confirmText: string
    onConfirm: () => Promise<void>
    onCancel?: () => void
    isLoading: boolean
}

const ConfirmationDialog: FC<IProps> = ({className, button, body, isLoading, confirmText, onCancel, onConfirm}) => {
    const baseClassName = "confirmation-dialog"

    const {getThemeClassName} = useTheme()

    const [show, setShow] = useState(false)
    const [isLoadingInternal, setIsLoadingInternal] = useState(false)

    const onConfirmInternal = () => {
        setIsLoadingInternal(true)

        onConfirm().then(() => setShow(false)).finally(() => setIsLoadingInternal(false))
    }
    
    const onCancelInternal = () => {
        setShow(false)
        if (onCancel) onCancel()
    }

    const renderButton = useMemo(() => {
        return button
    }, [button])

    return (
        <>
            <div className={`${baseClassName}__button`}>
                {renderButton(() => setShow(true))}
            </div>

            <DialogPortal
                className={`${className}`}
                type={"basic"}
                content={
                    <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
                        {
                            (isLoading || isLoadingInternal) ? (
                                <div className={`${baseClassName}__loader`}>
                                    <Loader/>
                                </div>
                            ) : (
                                <>
                                    {body}

                                    <div className={`${baseClassName}__buttons`}>
                                        <TextButton color={"blue"} onClick={onCancelInternal} textType={"text-only"}>
                                            Cancel
                                        </TextButton>

                                        <TextButton color={"blue"} textType={"text-only"}
                                                    onClick={onConfirmInternal}>
                                            {confirmText}
                                        </TextButton>
                                    </div>
                                </>
                            )
                        }
                    </div>
                }
                onCloseClick={function (): void {
                    throw new Error("Function not implemented.");
                }}
                show={show}
                isLoading={isLoadingInternal || isLoading}
            />
        </>
    )
}

export default ConfirmationDialog