import React, {
    createContext,
    FC,
    PropsWithChildren,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {useSidebar} from "./SidebarContext";
import {createPortal} from "react-dom";
import {AnimatePresence} from "framer-motion";
import {motion} from "framer-motion";
import {MediaQueries} from "../utils/MediaQueries";

interface ISideSheetContext {
    isOpen: boolean
    toggleSideSheet: () => void
    setContent: (key: string, el: ReactNode) => void
    setPortal: (el: HTMLDivElement) => void
    closeSideSheet: () => void
    openSideSheet: () => void
    key: string | undefined
}

export const SideSheetContext = createContext({} as ISideSheetContext)

export const SideSheetContextProvider: FC<PropsWithChildren> = ({children}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [content, setInternalContent] = useState<{ key: string, content: ReactNode } | null>(null)
    const [portal, setInternalPortal] = useState<HTMLElement | null>(null);

    const {isOpen: isSidebarOpen, toggleSidebar} = useSidebar()

    const toggleSideSheet = () => {
        if (isSidebarOpen && MediaQueries.large()) toggleSidebar()
        if (isOpen) setInternalContent(null)
        setIsOpen(value => !value)
    }

    const closeSideSheet = () => {
        setIsOpen(false)
        setInternalContent(null)
    }

    const openSideSheet = useCallback(() => {
        if (isSidebarOpen && MediaQueries.large()) toggleSidebar()
        setIsOpen(true)
    }, [isSidebarOpen, setIsOpen, toggleSidebar])

    const setContent = (key: string, content: ReactNode) => {
        if (!isOpen) openSideSheet()
        setInternalContent({key, content})
    }

    const setPortal = (el: HTMLDivElement) => setInternalPortal(el)

    const contentContainer = useMemo(() => {
        if (!content) return <></>

        return <motion.div
            className="side-sheet__motion"
            key={content.key}
            initial={{opacity: 0, translateX: 10}}
            animate={{opacity: 1, translateX: 0}}
            exit={{opacity: 0, translateX: 10}}
        >
            {content.content}
        </motion.div>
    }, [content])

    useEffect(() => {
        if (isSidebarOpen && MediaQueries.large()) closeSideSheet()
    }, [isSidebarOpen])

    return (
        <SideSheetContext.Provider
            value={{
                isOpen,
                toggleSideSheet,
                setContent,
                setPortal,
                closeSideSheet,
                openSideSheet,
                key: content?.key
            }}
        >
            <>

                {portal && createPortal(<AnimatePresence mode="wait">{contentContainer}</AnimatePresence>, portal)}
                {children}
            </>
        </SideSheetContext.Provider>
    )
}

export const useSideSheet = () => useContext(SideSheetContext)