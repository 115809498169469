import React, {FC} from "react";
import {motion} from "framer-motion";

const Tab = (Component: FC) => {
    return (
        <motion.div
            key="modal"
            className="tab"
            initial={{opacity: 0, translateY: -5}}
            animate={{opacity: 1, translateY: 0}}
            exit={{opacity: 0, translateY: 5}}
        >
            <Component/>
        </motion.div>
    )
}

export default Tab