import {createContext, FC, PropsWithChildren, useContext, useEffect, useState} from "react";

interface IThemeContext {
    theme: string
    setTheme: (theme: string) => void
    getThemeClassName: (className: string) => string
}

const getLocalTheme = (): string => {
    const theme = localStorage.getItem("theme")

    if (!theme) localStorage.setItem("theme", "dark")

    return localStorage.getItem("theme") ?? "dark"
}

export const ThemeContext = createContext({} as IThemeContext)

export const ThemeContextProvider: FC<PropsWithChildren> = ({children}) => {
    const [theme, _setTheme] = useState<string>(getLocalTheme());

    const getThemeClassName = (className: string) => {
        return className + "--" + theme
    }

    const setTheme = (theme: string) => {
        localStorage.setItem("theme", theme)
        _setTheme(theme)
    }

    useEffect(() => {
        document.documentElement.classList.add(theme)
    }, [theme]);

    return (
        <ThemeContext.Provider
            value={{
                theme: theme,
                getThemeClassName,
                setTheme
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}

export const useTheme = () => useContext(ThemeContext)