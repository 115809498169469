import React, {FC} from "react";
import {motion} from "framer-motion";

const Page = (Component: FC) => (
    <motion.div
        key="modal"
        className="page"
        initial={{opacity: 0, translateX: -10}}
        animate={{opacity: 1, translateX: 0}}
        exit={{opacity: 0, translateX: -10}}
    >
        <Component/>
    </motion.div>
)


export default Page