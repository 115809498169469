import {FieldValues, Path, useFormContext} from "react-hook-form";
import {FormInputProps} from "../Form";
import React, {ChangeEvent, DetailedHTMLProps, FormEvent, InputHTMLAttributes, ReactNode} from "react";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import Checkbox from "../../Checkbox/Checkbox";

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    name: string
    isLoading: boolean
    sideEffect?: (e: ChangeEvent<HTMLInputElement>) => void
    className?: string
    options?: RegisterOptions
}

function FormCheckbox<TSchema extends FieldValues>(
    {
        name,
        isLoading,
        className,
        options,
        ...props
    }: IProps
) {
    const {register} = useFormContext<TSchema>()
    // const {onChange, ...formProps} = register(name as Path<TSchema>, options)

    return (
        <Checkbox
            {...props}
            {...register(name as Path<TSchema>, options)}
            disabled={isLoading}
            className={className}
        />
    )
}

export default FormCheckbox