import React, {FC, PropsWithChildren, ReactNode} from "react";
import MaterialIcon from "../MaterialIcon/MaterialIcon";
import {useNavigate} from "react-router-dom";
import TextButton from "../Buttons/TextButton/TextButton";
import {useTheme} from "../../contexts/ThemeContext";
import Typo from "../Typo/Typo";

interface IProps extends PropsWithChildren {
    title?: ReactNode,
    icon?: ReactNode,
    parentTitle?: ReactNode
    description?: ReactNode
    onBackClick?: () => void
    backButton?: boolean
}

const PaneHeader: FC<IProps> = ({title, icon, parentTitle, children, description, backButton = false, onBackClick}) => {
    const baseClassName = "pane-header"
    const {getThemeClassName} = useTheme()
    const navigate = useNavigate()

    const onBackClickInternal = () => {
        if (onBackClick) return onBackClick()
        navigate(-1)
    }

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <div className={`${baseClassName}__container`}>
                {backButton && (
                    <TextButton
                        color={"blue"}
                        className={`${baseClassName}__back-button`}
                        name={"arrow_back"}
                        textType={"icon-only"}
                        onClick={onBackClickInternal}
                    >
                        <MaterialIcon name={"arrow_back"}/>
                    </TextButton>
                )}

                <div className={`${baseClassName}__title-container`}>
                    {
                        parentTitle &&
                        <Typo.Title size={"medium"} className={`${baseClassName}__parent-title`}>
                            {parentTitle}
                        </Typo.Title>
                    }

                    <div className={`${baseClassName}__title`}>
                        {icon}
                        <Typo.Display size={"medium"}>
                        {title}
                        </Typo.Display>
                    </div>

                    {
                        description &&
                        <Typo.Body className={`${baseClassName}__description`}>{description}</Typo.Body>
                    }
                </div>
            </div>
            {children}
        </div>
    )
}

export default PaneHeader