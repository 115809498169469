import {FC, PropsWithChildren} from "react";
import {Link, NavLink} from "react-router-dom";
import {useSidebar} from "../../contexts/SidebarContext";
import {MediaQueries} from "../../utils/MediaQueries";

interface IProps extends PropsWithChildren {
    to: string
}

const AppLink: FC<IProps> = ({children, to}) => {
    const {toggleSidebar, isOpen} = useSidebar()

    const baseClassName = "app-link"

    const handleClickLink = () => {
        if(MediaQueries.medium() && isOpen) toggleSidebar()
    }

    return (
        <NavLink to={to} onClick={handleClickLink} className={`${baseClassName}`}>{children}</NavLink>
    )
}

export default AppLink