import {api} from "../../../stores/redux/reducers/api";
import {DomainName} from "../models/DomainName";
import {Paginated} from "../../../types/models/Paginated";
import {GetDomainNameByCustomerId} from "../models/GetDomainNameByCustomerId";
import {GetDomainNameByCustomerIdAndRegistrantId} from "../models/GetDomainNameByCustomerIdAndRegistrantId";

const domainNamesApi = api.injectEndpoints({
    endpoints: build => ({
        getDomainNamesByCustomerId: build.query<Paginated<DomainName>, GetDomainNameByCustomerId>({
            query: ({
                customerId,
                page,
                pageSize
            }) => `customers/${customerId}/domains?page=${page}&pageSize=${pageSize}`,
            providesTags: [{type: "Domain"}]
        }),
        getDomainNamesByCustomerIdAndRegistrantId: build.query<Paginated<DomainName>, GetDomainNameByCustomerIdAndRegistrantId>({
            query: ({
                        customerId,
                        registrantId,
                        page,
                        pageSize
                    }) => `customers/${customerId}/registrants/${registrantId}/domains?page=${page}&pageSize=${pageSize}`,
            providesTags: [{type: "Domain"}]
        })
    })
})

export const {
    useGetDomainNamesByCustomerIdQuery,
    useGetDomainNamesByCustomerIdAndRegistrantIdQuery
} = domainNamesApi