import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AuthStatus} from "../../../types/constants/AuthStatus";
import {useAppSelector} from "../../../stores/redux/hooks/useSelector";

type UserInfo = {
    profile: {
        customerId: string
        sub: string
        givenName: string
        familyName: string
        email: string
        isBillable: boolean
    }
    accessToken: string
    expiresAt: number
}

type AuthState = {
    status: string
    userInfo?: UserInfo
}

const initialState: AuthState = {
    status: AuthStatus.INIT,
    userInfo: undefined
}

type AuthenticatedPayload = {
    profile: {
        sub: string
        customerId: string
        givenName: string
        familyName: string
        email: string
        isBillable: boolean
    }
    accessToken: string
    expiresAt: number
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authenticated(state, action: PayloadAction<AuthenticatedPayload>) {
            state.userInfo = action.payload
            state.status = AuthStatus.AUTHENTICATED
        },
        refreshed(state, action: PayloadAction<AuthenticatedPayload>) {
            state.userInfo = action.payload
        },
        unauthenticated(state) {
            state.status = AuthStatus.UNAUTHENTICATED
        },
        authenticationError(state) {
            state.status = AuthStatus.ERROR
        }
    }
})

export const useAuth = () => useAppSelector(state => state.auth)

export const {authenticated, unauthenticated, authenticationError, refreshed} = authSlice.actions