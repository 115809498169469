import {FC} from "react";
import {useTheme} from "../../../contexts/ThemeContext";
import {useSidebar} from "../../../contexts/SidebarContext";

interface IProps {
}

const Logo: FC<IProps> = () => {
    const {getThemeClassName} = useTheme()
    const {isOpen} = useSidebar()

    const baseClassName = "logo"
    const openClosedModifier = `--${isOpen ? "open" : "closed"}`

    return (
        <div className={`${baseClassName} ${baseClassName}${openClosedModifier} ${getThemeClassName(baseClassName)}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="183.72" height="183.721" viewBox="0 0 183.72 183.721" className={`${baseClassName}-path`}>
                <path id="Path_10" data-name="Path 10" d="M1000,314.995q-3.746,0-7.426.3-4.441.357-8.752,1.13A91.905,91.905,0,1,0,1000,314.995Zm0,171.829a79.489,79.489,0,0,1-40.7-11.154l16.761-38.23,7.848-18.349,12.9-30.679-8.93-21.072h-2.349L942.815,462.7a79.932,79.932,0,0,1,35.59-132.856l37.547,89.244H989.845L982,437.44h41.8l6.784,15.958h22.475l-55.39-126.476c.773-.024,1.552-.036,2.331-.036a79.969,79.969,0,0,1,0,159.938Z" transform="translate(-908.14 -314.995)" fill="#299ff1"/>
            </svg>


            <p>ARGO-CLOUD</p>
        </div>
    )
}

export default Logo