import {ThemeContextProvider} from "./ThemeContext";
import React, {FC, PropsWithChildren} from "react";
import {SidebarContextProvider} from "./SidebarContext";
import {UserContextProvider} from "./UserContext";
import {Toaster} from "react-hot-toast";
import {SideSheetContextProvider} from "./SideSheetContext";
import {AppContainerContextProvider} from "./AppContainerContext";

const Contexts: FC<PropsWithChildren> = ({children}) => {
    return (
        <UserContextProvider>
            <ThemeContextProvider>
                <AppContainerContextProvider>
                    <SidebarContextProvider>
                        <SideSheetContextProvider>
                            {children}
                            <Toaster/>
                        </SideSheetContextProvider>
                    </SidebarContextProvider>
                </AppContainerContextProvider>
            </ThemeContextProvider>
        </UserContextProvider>
    )
}

export default Contexts