import React, {FC} from "react";
import {useTheme} from "../../../../contexts/ThemeContext";
import Pane from "../../../../components/Pane/Pane";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import {useNavigate, useParams} from "react-router-dom";
import {RouteDefinitions} from "../../../../app/routes/RouteDefinitions";
import FilledButton from "../../../../components/Buttons/FilledButton/FilledButton";
import MaterialIcon from "../../../../components/MaterialIcon/MaterialIcon";
import ManagedAppIcon from "../../components/ManagedAppIcon/ManagedAppIcon";
import {useGetManagedAppQuery} from "../../redux/managedAppsApi";
import Label from "../../../../components/Label/Label";
import Subheading from "../../../../components/Subheading/Subheading";
import {useCustomerId} from "../../../Auth/hooks/useCustomerId";
import {MediaQueries} from "../../../../utils/MediaQueries";
import FAB from "../../../../components/Buttons/FAB/FAB";

const ManagedAppPage: FC = () => {
    const baseClassName = "managed-app-page"

    const {getThemeClassName} = useTheme()
    const navigate = useNavigate()
    const {id} = useParams<{ id: string }>()

    const onBackClick = () => navigate(RouteDefinitions.managedApps.navigationLink!)

    const customerId = useCustomerId()

    const {data, isLoading} = useGetManagedAppQuery({
        customerId: customerId ?? "",
        managedAppId: id ?? ""
    }, {
        skip: !customerId || !id
    })

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <Pane isLoading={isLoading} title="Managed Apps" showBackButton={MediaQueries.medium()}>
                <div className={`${baseClassName}__header`}>
                    <PaneHeader
                        backButton={!MediaQueries.medium()}
                        icon={data && <ManagedAppIcon name={data?.name}/>}
                        title={data && data?.name}
                        parentTitle={data && !MediaQueries.medium() && "Managed Apps"}
                        onBackClick={onBackClick}
                    />

                    {
                        // data && !MediaQueries.medium() &&
                        // <FilledButton
                        //     color="blue"
                        //     textType="icon-text"
                        //     className={`${baseClassName}__create-button`}
                        //     // onClick={() => navigate(RouteDefinitions.editManagedApp.path.replace(":id", data?.id))}
                        // >
                        //     <MaterialIcon name="edit"/>
                        //     Edit
                        // </FilledButton>
                    }
                </div>

                <Subheading><MaterialIcon name={"domain"}/> Address</Subheading>
                <Label>Domain</Label>
                <p className={`${baseClassName}__value`}>{data?.domain}</p>

                <Label>IP</Label>
                <p className={`${baseClassName}__value`}>{data?.ipv4}</p>

                <Subheading><MaterialIcon name={"tune"}/> Capacity</Subheading>
                <Label>Memory</Label>
                <p className={`${baseClassName}__value`}>{data?.memory} GB</p>

                <Label>Storage</Label>
                <p className={`${baseClassName}__value`}>{data?.storage} GB</p>

                <Label>CPU</Label>
                <p className={`${baseClassName}__value`}>{data?.cpu} core{data && data.cpu > 1 && "s"}</p>

                {
                    data && MediaQueries.medium() &&
                    <FAB className={`${baseClassName}__edit-button`}
                         icon={"edit"}
                         text={"Edit"}
                        // onClick={() => navigate(RouteDefinitions.editManagedApp.path.replace(":id", data?.id))}
                    />
                }
            </Pane>
        </div>
    )
}

export default ManagedAppPage