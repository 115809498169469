import {z} from 'zod'
import {ValidationError} from "../../../types/enums/ValidationError";

export const UpdateAddressRequest = z.object({
    street: z.string().min(2, `${ValidationError.CharMin}1`).max(255, `${ValidationError.CharMax}255`),
    houseNumber: z.string().min(1, `${ValidationError.CharMin}1`).max(255, `${ValidationError.CharMax}255`),
    suffix: z.string().max(255, `${ValidationError.CharMax}255`).optional(),
    postalCode: z.string().min(1, `${ValidationError.CharMin}1`).max(255, `${ValidationError.CharMax}255`),
    city: z.string().min(1, `${ValidationError.CharMin}1`).max(255, `${ValidationError.CharMax}255`),
    state: z.string().min(1, `${ValidationError.CharMin}1`).max(255, `${ValidationError.CharMax}255`),
})