import {FC} from "react";
import {useTheme} from "../../../../../contexts/ThemeContext";

interface IProps {
    onClick: (name: string) => void
    image: string
    label: string
    name: string
    description: string
}

const ManagedAppOption: FC<IProps> = ({onClick, image, label, name, description}) => {
    const baseClassName = "managed-app-option"

    const {getThemeClassName} = useTheme()

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`} onClick={() => onClick(name)}>
            <img src={image} alt={""}/>

            <div className={`${baseClassName}__info`}>
                <h3>{label}</h3>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default ManagedAppOption