import React, {FC, useMemo} from "react";
import {ProductStatus} from "../../features/Vdcs/models/ProductStatus";
import {useTheme} from "../../contexts/ThemeContext";
import MaterialSymbol from "../MaterialSymbol/MaterialSymbol";

interface IProps {
    status: string
    inverted?: boolean
}

const StatusIndicator: FC<IProps> = ({status, inverted}) => {
    const {getThemeClassName} = useTheme()

    const baseClassName = "status-indicator"

    const icon = useMemo(() => {
        switch (status.toLowerCase()) {
            case ProductStatus.Ready.toLowerCase():
                return "check"
            case ProductStatus.Creating.toLowerCase():
                return "clock_loader_40"
            case ProductStatus.Failed.toLowerCase():
                return "error"
            default:
                return "pending"
        }
    }, [status])

    return (
        <div className={`
            ${baseClassName} 
            ${getThemeClassName(baseClassName)}
            ${status === ProductStatus.Creating && baseClassName + "--creating"}
            ${inverted && baseClassName + "--inverted"}
            ${status === ProductStatus.Failed && baseClassName + "--failed"}
            ${status === ProductStatus.Ready && baseClassName + "--ready"}
        `}>
            <MaterialSymbol name={icon}/>
        </div>
    )
}

export default StatusIndicator