import React, {FC, useEffect, useMemo} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTheme} from "../../../../contexts/ThemeContext";
import {useAppSelector} from "../../../../stores/redux/hooks/useSelector";
import {useGetManagedAppsByCustomerIdQuery} from "../../redux/managedAppsApi";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import Table from "../../../../components/Table/Table";
import {DateHelper} from "../../../../utils/DateHelper";
import Pane from "../../../../components/Pane/Pane";
import {ManagedApp} from "../../models/ManagedApp";
import ManagedAppIcon from "../../components/ManagedAppIcon/ManagedAppIcon";
import FilledButton from "../../../../components/Buttons/FilledButton/FilledButton";
import MaterialIcon from "../../../../components/MaterialIcon/MaterialIcon";
import StatusChip from "../../../../components/StatusChip/StatusChip";
import {MediaQueries} from "../../../../utils/MediaQueries";
import FAB from "../../../../components/Buttons/FAB/FAB";

const ManagedAppsPage: FC = () => {
    const baseClassName = "managed-apps-page"

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const {getThemeClassName} = useTheme()

    const pageSize = 10
    const page = useMemo(() => parseInt(searchParams.get("page") ?? "0"), [searchParams])

    const customerId = useAppSelector(state => state.auth.userInfo?.profile.customerId)

    const {
        data,
        isLoading,
        isFetching,
        isError
    } = useGetManagedAppsByCustomerIdQuery({
        customerId: customerId ?? "",
        page,
        pageSize
    }, {
        skip: !customerId || customerId === ""
    })

    const onClick = (id: string) => {
        navigate(`/managed-apps/${id}`)
    }

    useEffect(() => {
        document.title = "Managed Apps"
    }, []);

    return (
        <div className={`
            ${baseClassName}
            ${getThemeClassName(baseClassName)}
            page-container
        `}>
            <Pane isLoading={isLoading} disableIfAccountNotComplete title="Managed Apps">
                {
                    !MediaQueries.medium() &&
                    <div className={`${baseClassName}__table-options`}>
                        <PaneHeader title={"Managed Apps"}/>
                            
                        <FilledButton
                            color="blue"
                            textType="icon-text"
                            className={`${baseClassName}__create-button`}
                            onClick={() => navigate("/managed-apps/select")}
                        >
                            <MaterialIcon name="add"/>
                            Create
                        </FilledButton>
                    </div>
                }

                <Table<ManagedApp>
                    className={`${baseClassName}__table`}
                    columns={[
                        {
                            name: "Name",
                            render: (managedApp) => (
                                <>
                                    <ManagedAppIcon name={managedApp.name}/>
                                    <p>{managedApp.name}</p>
                                </>
                            ),
                            className: `${baseClassName}__table-name`
                        },
                        {
                            name: "Domain",
                            render: (managedApp) => (
                                <a href={`https://${managedApp.domain}/`} rel="noopener noreferrer"
                                   target="_blank">{managedApp.domain}</a>
                            ),
                            className: `${baseClassName}__table-domain`
                        },
                        {
                            name: "Status",
                            render: (managedApp) => <StatusChip small={MediaQueries.medium()}
                                                                status={managedApp.status}/>,
                            headerClassName: `${baseClassName}__table-status`,
                            className: (managedApp) => `${baseClassName}__table-status ${baseClassName}__table-status--${managedApp.status.toLowerCase()}`
                        },
                        {
                            name: "Created at",
                            render: (managedApp) => (
                                <p>{DateHelper.getDateString(new Date(managedApp.createdAt))}</p>
                            ),
                            className: `${baseClassName}__table-createdAt`,
                            breakpoint: "medium"
                        }
                    ]}
                    onItemClick={(item) => onClick(item.id)}
                    items={data}
                    page={page}
                    pageSize={pageSize}
                    totalItems={data?.totalItems ?? 0}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    isError={isError}
                    setSearchParamsOnChange
                />

                {
                    data && MediaQueries.medium() &&
                    <FAB className={`${baseClassName}__create-fab`}
                         icon={"add"}
                         onClick={() => navigate("/managed-apps/select")}
                    />
                }
            </Pane>
        </div>
    )
}

export default ManagedAppsPage