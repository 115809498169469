import {ButtonHTMLAttributes, DetailedHTMLProps, forwardRef} from "react";
import MaterialSymbol from "../../MaterialSymbol/MaterialSymbol";
import {useTheme} from "../../../contexts/ThemeContext";

interface IProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    icon?: string
    text?: string
}

const FAB = forwardRef<HTMLButtonElement, IProps>(({icon, text, ...props}, ref) => {
    const baseClassName = "fab"
    
    const {getThemeClassName} = useTheme()
    
    return (
        <button ref={ref}
                {...props}
                className={`
                    ${baseClassName}
                    ${props.className}
                    label
                    ${getThemeClassName(baseClassName)}
                    ${!text && `${baseClassName}--icon-only`}
                    ${!icon && `${baseClassName}--text-only`}
                `}
        >
            {icon && <MaterialSymbol name={icon}/>}
            {text}
        </button>
    )
})

export default FAB