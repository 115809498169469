import React, {FC} from "react";
import {useTheme} from "../../../../../../contexts/ThemeContext";
import {useUserInfo} from "../../../../../../features/Auth/hooks/useUserInfo";
import MaterialSymbol from "../../../../../../components/MaterialSymbol/MaterialSymbol";
import {useSideSheet} from "../../../../../../contexts/SideSheetContext";
import TextButton from "../../../../../../components/Buttons/TextButton/TextButton";
import Typo from "../../../../../../components/Typo/Typo";
import {MediaQueries} from "../../../../../../utils/MediaQueries";

interface IProps {
    showModal: () => void
}

const AccountButton: FC<IProps> = ({showModal}) => {
    const baseClassName = "account-button"

    const {getThemeClassName} = useTheme()
    const userInfo = useUserInfo()
    const {key} = useSideSheet()

    return (
        <TextButton
            onClick={showModal}
            className={`${baseClassName} ${getThemeClassName(baseClassName)}`}
            color={"blue"}
            textType={MediaQueries.medium() ? "icon-only" : "icon-text"}
        >
            <MaterialSymbol
                name="account_circle"
                className={`
                    ${baseClassName}__account-circle 
                    ${key === "account" && `${baseClassName}__account-circle--selected`}
                `}
            />

            {
                !MediaQueries.medium() &&
                <Typo.Label size={"large"}
                            className={`${baseClassName}__name`}>
                    {userInfo?.profile.givenName} {userInfo?.profile.familyName}
                </Typo.Label>
            }
        </TextButton>
    )
}

export default AccountButton