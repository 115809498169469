import React, {FC} from "react";
import {useTheme} from "../../../../../../contexts/ThemeContext";
import AddressInfo from "./components/AddressInfo/AddressInfo";
import BankAccountVerification from "./components/BankAccountVerification/BankAccountVerification";

const CustomerInfo: FC = () => {
    const baseClassName = "billing-customer-info"

    const {getThemeClassName} = useTheme()

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            {/*<Typo.Headline className={`${baseClassName}__headline`}>Customer Info</Typo.Headline>*/}

            <div className={`${baseClassName}__content`}>
                <AddressInfo/>

                <BankAccountVerification/>
            </div>
        </div>
    )
}

export default CustomerInfo