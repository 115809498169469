export class RandomGenerator {
    static getRandomNumber(min: number, max: number, decimals: number) {
        const multiplier = Math.pow(10, decimals);

        min *= multiplier;
        max *= multiplier;

        const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;

        return randomValue / multiplier;
    }

    static getArrayOfRandomLength = (min: number, max: number) => Array.from(Array(RandomGenerator.getRandomNumber(min, max, 0)))
}