import React, {DetailedHTMLProps, forwardRef, HTMLAttributes, PropsWithChildren, useEffect, useMemo} from "react";
import {useTheme} from "../../contexts/ThemeContext";
import {useUserInfo} from "../../features/Auth/hooks/useUserInfo";
import {Link} from "react-router-dom";
import {RouteDefinitions} from "../../app/routes/RouteDefinitions";
import MaterialSymbol from "../MaterialSymbol/MaterialSymbol";
import {useGetCustomerQuery} from "../../features/Customers/redux/customersApi";
import {useCustomerId} from "../../features/Auth/hooks/useCustomerId";
import {useAppContainer} from "../../contexts/AppContainerContext";

interface IProps extends PropsWithChildren, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    title: string
    isLoading?: boolean,
    disableIfAccountNotComplete?: boolean
    showDisabledText?: boolean
    showBackButton?: boolean
}

const Pane = forwardRef<HTMLDivElement, IProps>((
    {
        children,
        className,
        title,
        showBackButton = false,
        isLoading = false,
        disableIfAccountNotComplete = false,
        showDisabledText = true,
        ...props
    },
    ref
) => {
    const baseClassName = "pane"

    const {getThemeClassName} = useTheme()
    const {setShowBackButton, setTitle} = useAppContainer()

    const userInfo = useUserInfo()
    const customerId = useCustomerId()

    const {data: customer} = useGetCustomerQuery(
        userInfo?.profile.customerId ?? "",
        {
            skip: !customerId
        }
    )

    const showOverlay = useMemo(() => {
        if (!userInfo) return false
        return disableIfAccountNotComplete && (!userInfo.profile.isBillable || !customer?.isBankAccountVerified);
    }, [userInfo, disableIfAccountNotComplete, customer?.isBankAccountVerified])

    useEffect(() => {
        setTitle(title)
        setShowBackButton(showBackButton)
    }, [title, showBackButton, setTitle, setShowBackButton])

    return (
        <div
            className={`
                ${baseClassName}
                ${getThemeClassName(baseClassName)}
                ${className}
            `}
            ref={ref}
            {...props}
        >
            <div
                className={`
                        ${baseClassName}__disabled-overlay 
                        ${showOverlay && `${baseClassName}__disabled-overlay--show`}
                    `}
            >
                {
                    showDisabledText && (
                        <>
                            <MaterialSymbol name="credit_card_off" className={`${baseClassName}__card-icon`}/>
                            <p>You need to fill out all your info before you can continue.</p>
                            <p>Go to <Link
                                to={RouteDefinitions.billing.navigationLink ?? RouteDefinitions.billing.path}>billing</Link> to
                                verify your bank-account</p>
                        </>
                    )
                }
            </div>

            {children}

            {
                isLoading &&
                <div className={`${baseClassName}__loader-container`}>
                    <span></span>
                </div>
            }
        </div>
    )
})

export default Pane