import {Outlet} from "react-router-dom";
import {useAppSelector} from "../../stores/redux/hooks/useSelector";
import {useEffect} from "react";
import {AuthStatus} from "../../types/constants/AuthStatus";
import {useUserContext} from "../../contexts/UserContext";

const ProtectedRoute = () => {
    const authStatus = useAppSelector(state => state.auth.status)

    const {loadUser} = useUserContext()

    useEffect(() => {
        authStatus === AuthStatus.INIT && loadUser()
    }, [authStatus, loadUser]);

    return (
        <>
            <Outlet/>
        </>
    )
}
export default ProtectedRoute