import {FieldValues, Path, useFormContext} from "react-hook-form";
import {RegisterOptions} from "react-hook-form/dist/types/validator";

interface IProps {
    name: string
    options?: RegisterOptions
}

function FormHiddenInput<TSchema extends FieldValues>({name, options}: IProps) {
    const {register} = useFormContext<TSchema>()

    return (
        <input type={"hidden"}
               {...register(name as Path<TSchema>, options)}
        />
    )
}

export default FormHiddenInput