import React, {FC, useEffect, useState} from "react";
import Pane from "../../../../components/Pane/Pane";
import {useCreateManagedAppMutation} from "../../redux/managedAppsApi";
import {CreateManagedAppRequest, CreateManagedAppRequestSchema} from "../../models/CreateManagedAppRequest";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import ManagedAppForm from "../../components/ManagedAppForm/ManagedAppForm";
import {RouteDefinitions} from "../../../../app/routes/RouteDefinitions";
import {useNavigate, useSearchParams} from "react-router-dom";
import ManagedAppIcon from "../../components/ManagedAppIcon/ManagedAppIcon";
import {useAppForm} from "../../../../hooks/useAppForm";
import {FormProvider} from "react-hook-form";
import {useAuth} from "../../../Auth/slices/authSlice";
import {MediaQueries} from "../../../../utils/MediaQueries";

interface IProps {

}

const CreateManagedAppPage: FC<IProps> = () => {
    const baseClassName = "create-managed-app-page"

    const [searchParams] = useSearchParams();

    const navigate = useNavigate()

    const {userInfo} = useAuth()

    const [initData] = useState<CreateManagedAppRequest>({
        customerId: userInfo?.profile.customerId ?? "",
        name: searchParams.get("name") as CreateManagedAppRequest["name"],
        cpu: 2,
        memory: 4,
        storage: 25,
        subdomain: undefined,
        variables: undefined
    })

    const {
        form,
        onFormSubmit,
        isLoading,
        isSuccess,
        result
    } = useAppForm(
        initData,
        CreateManagedAppRequestSchema,
        useCreateManagedAppMutation
    )

    useEffect(() => {
        if (result?.success) navigate(`${RouteDefinitions.managedApps.path}/${result?.response.id}`)
    }, [isSuccess, result, navigate]);

    if (searchParams.get("name") == null) navigate(RouteDefinitions.selectManagedApp.path)

    return (
        <div className={baseClassName}>
            <Pane isLoading={isLoading} title="Managed Apps" showBackButton={MediaQueries.medium()}>
                <div className={`${baseClassName}__table-options`}>
                    <PaneHeader
                        icon={<ManagedAppIcon name={initData.name}/>}
                        title={initData.name}
                        parentTitle={!MediaQueries.medium() && `Managed Apps`}
                        onBackClick={() => navigate(RouteDefinitions.selectManagedApp.path)}
                        backButton={!MediaQueries.medium()}
                    />
                </div>

                {
                    initData.name &&
                    <FormProvider {...form}>
                        <ManagedAppForm
                            onSubmit={onFormSubmit}
                            isLoading={isLoading}
                            onCancel={() => navigate(`${RouteDefinitions.selectManagedApp.path}`)}
                            name={initData.name}
                        />
                    </FormProvider>
                }
            </Pane>
        </div>
    )
}

export default CreateManagedAppPage