import {DetailedHTMLProps, FC, HTMLAttributes} from "react";
import {useTheme} from "../../contexts/ThemeContext";

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    type?: "elevated" | "filled" | "outlined"
    dragging?: boolean
}

const Card: FC<IProps> = ({children, type = "elevated", dragging, ...props}) => {
    const {getThemeClassName} = useTheme()

    const baseClassName = "card"

    return (
        <div
            {...props}
            className={`${baseClassName} ${baseClassName}--${type} ${getThemeClassName(baseClassName)} ${dragging && `${baseClassName}--dragging`} ${props.className}`}>
            {children}
        </div>
    )
}

export default Card