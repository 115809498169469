import {DetailedHTMLProps, FC, HTMLAttributes, useMemo} from "react";

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    name: string
    className?: string
}

const MaterialSymbol: FC<IProps> = ({name, className, ...props}) => {
    const classes = useMemo(() => {
        const base = className ? `material-symbols-outlined material-symbol ${className}` : `material-symbols-outlined material-symbol`

        return `${base}`
    }, [className])

    return (
        <span className={classes} {...props}>{name}</span>
    )
}

export default MaterialSymbol