import React, {FC, useMemo, useRef} from "react";
import {useTheme} from "../../../../contexts/ThemeContext";
import {Navigate, useParams, useSearchParams} from "react-router-dom";
import {RouteDefinitions} from "../../../../app/routes/RouteDefinitions";
import Pane from "../../../../components/Pane/Pane";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import {MediaQueries} from "../../../../utils/MediaQueries";
import {useDeleteRegistrantMutation, useGetRegistrantQuery} from "../../redux/registrantsApi";
import {useCustomerId} from "../../../Auth/hooks/useCustomerId";
import Typo from "../../../../components/Typo/Typo";
import RegistrantDialog from "../../components/RegistrantsTab/RegistrantDialog/RegistrantDialog";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/ConfirmationDialog";
import FilledButton from "../../../../components/Buttons/FilledButton/FilledButton";
import MaterialSymbol from "../../../../components/MaterialSymbol/MaterialSymbol";
import {DomainName} from "../../models/DomainName";
import Table from "../../../../components/Table/Table";
import {
    useGetDomainNamesByCustomerIdAndRegistrantIdQuery
} from "../../redux/domainNamesApi";

const RegistrantPage: FC = () => {
    const baseClassName = "registrant-page"

    const {getThemeClassName} = useTheme()

    const customerId = useCustomerId()

    const {id} = useParams<{ id: string }>()

    const {data, isLoading} = useGetRegistrantQuery({
        customerId: customerId ?? "",
        registrantId: id ?? ""
    }, {skip: !id || !customerId})

    const [deleteAction, {
        reset: reset,
        data: deleteData,
        isLoading: isDeleteLoading,
        isError: isDeleteError
    }] = useDeleteRegistrantMutation();

    const [searchParams] = useSearchParams()

    const pageSize = 5
    const page = useMemo(() => parseInt(searchParams.get("page") ?? "0"), [searchParams])

    const {
        data: domainNames,
        isLoading: isLoadingDomains,
        isFetching: isFetchingDomains,
        isError: isErrorDomains
    } = useGetDomainNamesByCustomerIdAndRegistrantIdQuery({
        customerId: customerId ?? "",
        registrantId: id ?? "",
        page: page,
        pageSize: pageSize
    }, {
        skip: !customerId || !id
    })

    if (id == null) return <Navigate to={RouteDefinitions.domains.navigationLink!}/>


    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <Pane isLoading={isLoading} title={"Registrant"} showBackButton={MediaQueries.medium()}>
                <div className={`${baseClassName}__header`}>
                    <PaneHeader
                        backButton={!MediaQueries.medium()}
                        title={data && data.alias}
                        parentTitle={data && !MediaQueries.medium() && "Registrants"}
                    />

                    <div className={`${baseClassName}__header-buttons`}>
                        {data?.deletedAt == null && <RegistrantDialog/>}

                        {data?.deletedAt == null && <ConfirmationDialog
                            button={(onClick) =>
                                <FilledButton color={"red"}
                                              onClick={onClick}
                                              textType={"icon-text"}
                                              className={`${baseClassName}__delete-button`}
                                >
                                    <MaterialSymbol name={"delete"}/> Delete
                                </FilledButton>
                            }
                            onCancel={reset}
                            isLoading={isDeleteLoading || isLoading}
                            body={
                                <>
                                    <Typo.Headline size={"small"} className={`${baseClassName}__title`}>Delete
                                        registrant</Typo.Headline>

                                    <Typo.Body className={`${baseClassName}__body`}>Are you sure you want to delete
                                        "{data?.alias}"?</Typo.Body>
                                </>
                            }
                            confirmText={"Delete"}
                            onConfirm={() => deleteAction({customerId: customerId!, id: id!}).then()}
                        />}
                    </div>
                </div>


                <table>
                    <tbody>
                    <tr>
                        <td><Typo.Label>Alias</Typo.Label></td>
                        <td><Typo.Body>{data?.alias ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Company name</Typo.Label></td>
                        <td><Typo.Body>{data?.companyName ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Job title</Typo.Label></td>
                        <td><Typo.Body>{data?.jobTitle ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>First name</Typo.Label></td>
                        <td><Typo.Body>{data?.firstName ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Last name</Typo.Label></td>
                        <td><Typo.Body>{data?.lastName ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Street</Typo.Label></td>
                        <td><Typo.Body>{data?.street ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>House number</Typo.Label></td>
                        <td><Typo.Body>{data?.houseNumber ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Suffix</Typo.Label></td>
                        <td><Typo.Body>{data?.suffix ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Postal code</Typo.Label></td>
                        <td><Typo.Body>{data?.postalCode ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>City</Typo.Label></td>
                        <td><Typo.Body>{data?.city ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>State</Typo.Label></td>
                        <td><Typo.Body>{data?.state ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Phone</Typo.Label></td>
                        <td><Typo.Body>{data?.phone ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Fax</Typo.Label></td>
                        <td><Typo.Body>{data?.fax ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Email</Typo.Label></td>
                        <td><Typo.Body>{data?.email ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Country</Typo.Label></td>
                        <td><Typo.Body>{data?.country ?? "-"}</Typo.Body></td>
                    </tr>

                    <tr>
                        <td><Typo.Label>Deleted</Typo.Label></td>
                        <td><Typo.Body>{data?.deletedAt ? "Yes" : "No"}</Typo.Body></td>
                    </tr>
                    </tbody>
                </table>
                
                <Typo.Title className={`${baseClassName}__domain-title`}>Domain names</Typo.Title>
                <Table<DomainName>
                    className={`${baseClassName}__domain-table`}
                    columns={[
                        {
                            name: "Second-level domain (SLD)",
                            className: `${baseClassName}__sld`,
                            render: (item) => <p>{item.sld}</p>
                        },
                        {
                            name: "Top-level domain (TLD)",
                            className: `${baseClassName}__tld`,
                            render: (item) => <p>.{item.tld}</p>
                        },
                        {
                            name: "Registrant",
                            className: `${baseClassName}__registrant`,
                            render: (item) => <p>{item.registrantAlias}</p>
                        }
                    ]}
                    onItemClick={() => {
                    }}
                    page={page}
                    pageSize={pageSize}
                    totalItems={domainNames?.totalItems ?? 0}
                    isLoading={isLoadingDomains}
                    isFetching={isFetchingDomains}
                    isError={isErrorDomains}
                    items={domainNames}
                />
            </Pane>
        </div>
    )
}

export default RegistrantPage