import {z} from 'zod';

export const UpdateRegistrantSchema = z.object({
    customerId: z.string().nullable(),
    id: z.string().nullable(),
    alias: z.string().min(1).max(255),
    companyName: z.string().max(255).nullable(),
    jobTitle: z.string().max(255),
    firstName: z.string().min(1).max(255),
    lastName: z.string().min(1).max(255),
    street: z.string().min(1).max(255),
    houseNumber: z.string(),
    suffix: z.string().max(255).nullable(),
    postalCode: z.string().min(1).max(255),
    city: z.string().min(1).max(255),
    state: z.string().min(1).max(255),
    phone: z.string().min(1).max(255),
    fax: z.string().max(10).nullable(),
    email: z.string().min(1).max(255),
    country: z.string().min(1).max(255),
})

export type UpdateRegistrantSchema = z.infer<typeof UpdateRegistrantSchema>