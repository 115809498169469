import {FC, PropsWithChildren} from "react";
import {useTheme} from "../../contexts/ThemeContext";

const Subheading: FC<PropsWithChildren> = ({children}) => {
    const baseClassName = "subheading"

    const {getThemeClassName} = useTheme()

    return (
        <p className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>{children}</p>
    )
}

export default Subheading