import {z} from "zod";
import {ValidationError} from "../../../types/enums/ValidationError";

const {Required, CharMin, Max, Min} = ValidationError;

export const CreateVdcRequestSchema = z.object({
    customerId: z.string(),
    name: z.string().min(1, Required).min(4, `${CharMin}4`),
    cpuLimit: z.number().min(1, `${Min}1`).max(32, `${Max}32`),
    memoryLimit: z.number().min(1, `${Min}1`).max(512, `${Max}512`),
    storageLimit: z.number().min(1, `${Min}1`).max(4000, `${Max}4000`),
    ipLeases: z.number().min(1, `${Min}1`).max(12, `${Max}12`),
})

export type CreateVdcRequest = z.infer<typeof CreateVdcRequestSchema>