import React, {FC, FormEvent} from "react";
import ToolTip from "../../../../components/ToolTip/ToolTip";
import FormOutlinedTextField from "../../../../components/Form/components/FormOutlinedTextField";
import FormHiddenInput from "../../../../components/Form/components/FormHiddenInput";
import {useTheme} from "../../../../contexts/ThemeContext";
import FilledButton from "../../../../components/Buttons/FilledButton/FilledButton";
import OutlinedButton from "../../../../components/Buttons/OutlinedButton/OutlinedButton";
import MaterialSymbol from "../../../../components/MaterialSymbol/MaterialSymbol";
import {MediaQueries} from "../../../../utils/MediaQueries";

interface IProps {
    onSubmit: (e: FormEvent) => void
    isLoading: boolean
    onCancel: () => void
    name: string
}

const VdcForm: FC<IProps> = (
    {
        onSubmit,
        isLoading,
        onCancel,
        name
    }
) => {
    const baseClassName = "vdc-form"

    const {getThemeClassName} = useTheme()

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <form onSubmit={onSubmit}>
                <FormHiddenInput name={"customerId"}/>

                <div className={`${baseClassName}__inputs`}>
                    {
                        !name &&
                        <>
                            <div className={`${baseClassName}__label-container`}>
                                <p className={`${baseClassName}__label`}>General</p>
                            </div>
                            <div className={`${baseClassName}__input`}>
                                <FormOutlinedTextField
                                    name="name"
                                    label="Name"
                                    isLoading={isLoading}
                                />
                            </div>
                        </>
                    }

                    <div className={`${baseClassName}__label-container`}>
                        <div className={`${baseClassName}__quota-label`}>
                            <p className={`${baseClassName}__label`}>Quota</p>

                            <div className={`${baseClassName}__tool-tip`}>
                                <MaterialSymbol name={"info"} className={`${baseClassName}__tool-tip-symbol`}/>
                                <ToolTip
                                    text={"The quota of your Private Cloud is the maximum amount of resources that can be used"}/>
                            </div>
                        </div>
                    </div>

                    <div className={`${baseClassName}__input`}>
                        <FormOutlinedTextField
                            name="cpuLimit"
                            label="CPU limit"
                            supportingText={`Min. 1, Max. 32`}
                            type="number"
                            unit="core(s)"
                            min={1}
                            max={32}
                            options={{valueAsNumber: true}}
                            isLoading={isLoading}
                        />
                    </div>

                    <div className={`${baseClassName}__input`}>
                        <FormOutlinedTextField
                            name="memoryLimit"
                            label="Memory limit"
                            supportingText={`Min. 1, Max. 512`}
                            type="number"
                            unit="GB"
                            min={1}
                            max={512}
                            options={{valueAsNumber: true}}
                            isLoading={isLoading}
                        />
                    </div>

                    <div className={`${baseClassName}__input`}>
                        <FormOutlinedTextField
                            name="storageLimit"
                            label="Storage limit"
                            supportingText={`Min. 1, Max. 4000`}
                            type="number"
                            unit="GB"
                            min={1}
                            max={4000}
                            options={{valueAsNumber: true}}
                            isLoading={isLoading}
                        />
                    </div>

                    <div className={`${baseClassName}__input`}>
                        <FormOutlinedTextField
                            name="ipLeases"
                            label="IP leases"
                            supportingText={`Min. 1, Max. 32`}
                            type="number"
                            min={1}
                            max={12}
                            options={{valueAsNumber: true}}
                            isLoading={isLoading}
                        />
                    </div>
                </div>

                <div className={`${baseClassName}__buttons`}>
                    <FilledButton color={"blue"}
                                  textType={"text-only"}
                                  type={"submit"}
                                  className={`${baseClassName}__create-button`}
                    >
                        Create
                    </FilledButton>

                    {
                        !MediaQueries.medium() &&
                        <OutlinedButton color={"blue"}
                                        textType={"text-only"}
                                        type={"button"}
                                        onClick={onCancel}
                        >
                            Cancel
                        </OutlinedButton>
                    }
                </div>
            </form>
        </div>
    )
}

export default VdcForm