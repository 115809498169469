import {FieldValues, get, Path, PathValue, useForm, useFormContext} from "react-hook-form";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import OutlinedTextField from "../../TextFields/OutlinedTextField/OutlinedTextField";
import {ReactNode} from "react";
import {FormInputProps} from "../Form";

interface IProps<TSchema extends FieldValues> {
    label: string
    name: string
    isLoading: boolean
    className?: string
    supportingText?: ReactNode
    options?: RegisterOptions
    max?: number
    min?: number
    unit?: string
    type?: "text" | "number" | "password" | undefined
}

function FormOutlinedTextField<TSchema extends FieldValues>(
    {
        label,
        name,
        className,
        supportingText,
        unit,
        options,
        max,
        min,
        type = "text",
        isLoading
    }: IProps<TSchema>
) {
    const {formState, register, getValues, setValue} = useFormContext<TSchema>()

    const onIncrement = () => {
        let current = getValues(name as Path<TSchema>)

        if (max === undefined || current + 1 <= max) setValue(
            name as Path<TSchema>,
            current + 1 as PathValue<TSchema, Path<TSchema>>
        )
    }

    const onDecrement = () => {
        let current = getValues(name as Path<TSchema>)

        if (min === undefined || current - 1 >= min) setValue(
            name as Path<TSchema>,
            current - 1 as PathValue<TSchema, Path<TSchema>>
        )
    }

    return (
        <OutlinedTextField
            className={className}
            label={label}
            unit={unit}
            disabled={isLoading}
            errors={get(formState.errors, name) && get(formState.errors, name).message}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            supportingText={supportingText}
            {...register(name as Path<TSchema>, options)}
            type={type}
        />
    )
}

export default FormOutlinedTextField