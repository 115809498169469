import React, {FC, FormEvent, useMemo} from "react";
import FormOutlinedTextField from "../../../../components/Form/components/FormOutlinedTextField";
import GitLabForm from "../GitLabForm/GitLabForm";
import {useTheme} from "../../../../contexts/ThemeContext";
import Label from "../../../../components/Label/Label";
import {useFormContext} from "react-hook-form";
import FilledButton from "../../../../components/Buttons/FilledButton/FilledButton";
import OutlinedButton from "../../../../components/Buttons/OutlinedButton/OutlinedButton";
import FormHiddenInput from "../../../../components/Form/components/FormHiddenInput";
import {useCustomerId} from "../../../Auth/hooks/useCustomerId";
import {MediaQueries} from "../../../../utils/MediaQueries";

interface IProps {
    onSubmit: (e: FormEvent) => void
    isLoading: boolean
    onCancel: () => void
    name: string
}

const ManagedAppForm: FC<IProps> = (
    {
        onSubmit,
        isLoading,
        onCancel,
        name
    }
) => {
    const {watch} = useFormContext()

    const baseClassName = "managed-app-form"

    const {getThemeClassName} = useTheme()

    const customerId = useCustomerId();

    const renderInputs = useMemo(() => {
        switch (name) {
            case "GitLab":
                return <GitLabForm isLoading={isLoading}/>
            default:
                return <></>
        }
    }, [name, isLoading])

    const subdomain = watch("subdomain")

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <form onSubmit={onSubmit}>
                <div className={`${baseClassName}__inputs`}>
                    <FormHiddenInput name={"customerId"}/>

                    <Label>Capacity</Label>
                    <FormOutlinedTextField
                        label={"CPU"}
                        name={"cpu"}
                        type={"number"}
                        unit={"core(s)"}
                        min={2}
                        max={32}
                        options={{valueAsNumber: true}}
                        isLoading={isLoading}
                    />

                    <FormOutlinedTextField
                        label={"Storage"}
                        name={"storage"}
                        type={"number"}
                        unit={"GB"}
                        min={25}
                        max={5000}
                        options={{valueAsNumber: true}}
                        isLoading={isLoading}
                    />

                    <FormOutlinedTextField
                        label={"Memory"}
                        name={"memory"}
                        type={"number"}
                        unit={"GB"}
                        min={4}
                        max={64}
                        options={{valueAsNumber: true}}
                        isLoading={isLoading}
                    />

                    <Label>Info</Label>
                    <div className={`${baseClassName}__subdomain`}>
                        <FormOutlinedTextField
                            label={"Subdomain (optional)"}
                            name={"subdomain"}
                            isLoading={isLoading}
                            supportingText={<>gitlab.{subdomain && subdomain !== "" && `${subdomain}.`}{customerId}.argomanagedapps.net</>}
                        />
                    </div>

                    {renderInputs}
                </div>

                <div className={`${baseClassName}__buttons`}>
                    <FilledButton
                        color={"blue"}
                        textType={"text-only"}
                        type={"submit"}
                        className={`${baseClassName}__create-button`}
                    >
                        {/*<MaterialIcon*/}
                        {/*    name={"check"}*/}
                        {/*/>*/}
                        Create
                    </FilledButton>

                    {
                        !MediaQueries.medium() &&
                        <OutlinedButton
                            color={"blue"}
                            textType={"text-only"}
                            onClick={onCancel}
                            type={"button"}
                        >
                            Cancel
                        </OutlinedButton>
                    }
                </div>
            </form>
        </div>
    )
}

export default ManagedAppForm