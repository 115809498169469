import {RefObject, useCallback, useEffect} from "react";

export const useOutsideClickDetection = (ref: RefObject<HTMLElement | null>, onOutsideClick: () => void) => {
    const handleMouseDown = useCallback((e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
            onOutsideClick()
        }
    }, [ref, onOutsideClick])

    return useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)

        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [handleMouseDown])
}