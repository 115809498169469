import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "../types/RootState";

export const api = createApi({
    reducerPath: "api",
    tagTypes: ["Vdcs", "Me", "ManagedApps", "Customer", "Registrant", "Domain"],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API,
        prepareHeaders: (headers, { getState }) => {
            const accessToken = (getState() as RootState).auth.userInfo?.accessToken

            if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`)

            return headers
        }
    }),
    endpoints: () => ({})
})