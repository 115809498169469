import {ButtonHTMLAttributes, DetailedHTMLProps, FC, PropsWithChildren, useMemo} from "react";

interface IProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, PropsWithChildren {
    textType?: "icon-only" | "icon-text" | "text-only"
    iconLocation?: "behind" | "in-front"
}

export type ButtonProps = IProps;

const Button: FC<IProps> = (
    {
        children,
        textType,
        className,
        iconLocation = "front",
        ...props
    }
) => {
    const fullClassName = useMemo(() => {
        let tmp = "button";

        if (textType) tmp += ` button--${textType}`;
        if (iconLocation) tmp += ` button--${iconLocation}`;

        return tmp + " " + className;
    }, [textType, className, iconLocation])

    return (
        <button {...props} className={fullClassName}>{children}</button>
    )
}

export default Button