import {z} from "zod";
import {ValidationError} from "../../../types/enums/ValidationError";

const {Required, CharMax} = ValidationError;

export const GitLabVariablesSchema = z.object({
    name: z.literal("GitLab"),
    variables: z.object({
        replyTo: z.string().min(1, Required).email(),
        displayName: z.string().min(1, Required).max(64, `${CharMax}64`),
        rootEmail: z.string().email(),
        rootPassword: z.string()
    }).optional()
})
