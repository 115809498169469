import {configureStore, isPlain} from "@reduxjs/toolkit";
import {rootReducer} from "./reducers/rootReducer";
import {BaseHttpError} from "../../types/errors/BaseHttpError";
import {api} from "./reducers/api";
import {setupListeners} from "@reduxjs/toolkit/query";

const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                isSerializable: (value: any) => {
                    if (value instanceof BaseHttpError) {
                        return true
                    } else {
                        return isPlain(value)
                    }
                }
            }
        }).concat(api.middleware),
    reducer: rootReducer
})

export default store

setupListeners(store.dispatch)