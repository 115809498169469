export interface IRouteDefinition {
    name: string
    path: string
    sidebar: boolean
    navigationLink?: string
    iconName?: string
    iconType?: "regular" | "outlined" | "round" | "sharp" | "two-tone"
    children?: IRouteDefinition[]
}

interface IRoutes {
    oauthRedirect: IRouteDefinition

    billing: IRouteDefinition
    invoice: IRouteDefinition

    managedApps: IRouteDefinition
    managedApp: IRouteDefinition
    selectManagedApp: IRouteDefinition
    createManagedApp: IRouteDefinition

    domains: IRouteDefinition
    registrant: IRouteDefinition

    vdcs: IRouteDefinition
    vdc: IRouteDefinition
    editVdc: IRouteDefinition
    createVdc: IRouteDefinition
}

export const RouteDefinitions: IRoutes = {
    managedApps: {
        name: "Managed Apps",
        path: "/managed-apps",
        navigationLink: "/managed-apps?page=0&pageSize=10",
        sidebar: true,
        iconName: "widgets",
        iconType: "outlined"
    },
    managedApp: {
        name: "Managed App",
        path: "/managed-apps/:id",
        sidebar: false
    },
    selectManagedApp: {
        name: "Managed Apps",
        path: "/managed-apps/select",
        sidebar: false
    },
    createManagedApp: {
        name: "Managed Apps",
        path: "/managed-apps/create",
        sidebar: false
    },
    domains: {
        name: "Domains",
        path: "domains/*",
        navigationLink: "/domains",
        sidebar: true,
        iconName: "domain",
        children: [
            {
                name: "Domain names",
                path: "domain-names",
                navigationLink: "domain-names?page=0&pageSize=10",
                sidebar: false
            },
            {
                name: "Registrants",
                path: "registrants",
                navigationLink: "registrants?page=0&pageSize=10",
                sidebar: false
            }
        ]
    },
    registrant: {
        name: "Registrant",
        path: "/registrants/:id",
        sidebar: false
    },
    vdcs: {
        name: "Private Cloud",
        path: "/vdcs",
        sidebar: true,
        navigationLink: "/vdcs?page=0&pageSize=10",
        iconName: "storage"
    },
    vdc: {
        name: "Private Cloud",
        path: "/vdcs/:id",
        sidebar: false
    },
    editVdc: {
        name: "Private Cloud",
        path: "/vdcs/:id/edit",
        sidebar: false
    },
    createVdc: {
        name: "Create Private Cloud",
        path: "/vdcs/create",
        sidebar: false
    },
    billing: {
        name: "Billing",
        path: "/billing/*",
        sidebar: true,
        navigationLink: "/billing?page=0&pageSize=10#invoices",
        iconName: "payments",
        children: [
            {
                name: "Invoices",
                path: "invoices",
                navigationLink: "invoices?page=0&pageSize=10",
                sidebar: false
            },
            {
                name: "Customer Info",
                path: "customer-info",
                navigationLink: "customer-info",
                sidebar: false
            }
        ]
    },
    invoice: {
        name: "Invoice",
        path: "/invoices/:id",
        sidebar: false
    },
    oauthRedirect: {
        name: "OAuth Redirect",
        path: "/oauth_redirect",
        sidebar: false
    }
}