import React, {FC, useEffect} from "react";
import {useTheme} from "../../../../contexts/ThemeContext";
import Pane from "../../../../components/Pane/Pane";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import Invoices from "./components/Invoices/Invoices";
import TonalButton from "../../../../components/Buttons/TonalButton/TonalButton";
import Typo from "../../../../components/Typo/Typo";
import Card from "../../../../components/Card/Card";
import FilledButton from "../../../../components/Buttons/FilledButton/FilledButton";
import CustomerInfo from "./components/CustomerInfo/CustomerInfo";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useUserContext} from "../../../../contexts/UserContext";
import {MediaQueries} from "../../../../utils/MediaQueries";
import Tabs from "../../../../components/Tabs/Tabs";
import {RouteDefinitions} from "../../../../app/routes/RouteDefinitions";
import {AnimatePresence} from "framer-motion";
import Tab from "../../../../components/Tabs/components/Tab";

interface IProps {

}

const BillingPage: FC<IProps> = () => {
    const {getThemeClassName} = useTheme()

    const navigate = useNavigate()
    const location = useLocation()

    const baseClassName = "billing-page"

    const {isBillable} = useUserContext()

    useEffect(() => {
        document.title = "Billing"
    }, []);

    const cardType = "elevated"

    return (
        <div className={`
            ${baseClassName}
            ${getThemeClassName(baseClassName)}
            page
        `}>
            <Pane className={`${baseClassName}__pane`} title={"Billing"}>
                <div className={`${baseClassName}__header`}>
                    <PaneHeader title={!MediaQueries.medium() && "Billing"}
                                description={"View your invoices and view or update your payment details."}></PaneHeader>
                </div>

                <div className={`${baseClassName}__cards`}>
                    {
                        !true &&
                        <Card className={`${baseClassName}__card`} type={cardType}>
                            <div className={`${baseClassName}__card-text`}>
                                <Typo.Headline>Next invoice</Typo.Headline>
                                <Typo.Body>July 22, 2024</Typo.Body>
                            </div>

                            <div className={`${baseClassName}__card-buttons`}>
                                <TonalButton textType={"text-only"}>View products</TonalButton>
                            </div>
                        </Card>
                    }

                    {
                        !isBillable &&
                        <Card className={`${baseClassName}__card ${baseClassName}__payment-details`} type={cardType}>
                            <div className={`${baseClassName}__card-text`}>
                                <Typo.Headline>Payment details <span
                                    className={`${baseClassName}__card-error`}></span></Typo.Headline>
                                <Typo.Body>We are missing some information. Fill out your details
                                    to access our products.</Typo.Body>
                            </div>

                            <div className={`${baseClassName}__card-buttons`}>
                                <FilledButton color={"blue"} textType={"text-only"}
                                              onClick={() => navigate("#customer-info")}>
                                    To payment details
                                </FilledButton>
                            </div>
                        </Card>
                    }
                </div>
                
                
                <Tabs routeDefinition={RouteDefinitions.billing}/>
                
                <AnimatePresence mode={"wait"}>
                    <Routes location={location} key={location.pathname}>
                        <Route
                            path="/"
                            element={<Navigate to={RouteDefinitions.billing.children![0].navigationLink!} replace/>}
                        />

                        <Route
                            path={RouteDefinitions.billing.children![0].path}
                            element={Tab(Invoices)}
                        />s

                        <Route
                            path={RouteDefinitions.billing.children![1].path}
                            element={Tab(CustomerInfo)}
                        />
                    </Routes>
                </AnimatePresence>
            </Pane>
        </div>
    )
}

export default BillingPage