import React, {FC} from "react";
import FormOutlinedTextField from "../../../../components/Form/components/FormOutlinedTextField";
import Label from "../../../../components/Label/Label";

interface IProps {
    isLoading: boolean
}

const GitLabForm: FC<IProps> = ({isLoading}) => {
    return (
        <>
            <Label>Email</Label>
            <FormOutlinedTextField label={"Display name"} name={"variables.displayName"} isLoading={isLoading}/>
            <FormOutlinedTextField label={"Reply to address"} name={"variables.replyTo"} isLoading={isLoading}/>

            <Label>Admin Account</Label>
            <FormOutlinedTextField label={"Email"} name={"variables.rootEmail"} isLoading={isLoading}/>
            <FormOutlinedTextField label={"Password"} name={"variables.rootPassword"} isLoading={isLoading} type={"password"}/>
        </>
    )
}

export default GitLabForm