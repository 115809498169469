import {FetchBaseQueryError, MutationDefinition} from "@reduxjs/toolkit/query";
import {FormEvent, useMemo, useState} from "react";
import {BadRequestBody} from "../types/models/BadRequestBody";
import {FieldErrors, useForm, UseFormReturn} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {UseMutation} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {SubmitErrorHandler} from "react-hook-form/dist/types/form";

type UseAppForm<TSchema extends z.Schema<any, any>, TResult> = {
    form: UseFormReturn<z.TypeOf<TSchema>>
    formReset: () => void
    onFormSubmit: (e: FormEvent) => void
    is500Error: boolean
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
    result: TResult | (TResult & undefined) | undefined
}

export function useAppForm<
    TSchema extends z.Schema<any, any>,
    TResult
>(
    initData: z.infer<TSchema>,
    validator: TSchema,
    mutation: UseMutation<MutationDefinition<z.infer<TSchema>, any, any, TResult>>,
    onError?: SubmitErrorHandler<FieldErrors<z.TypeOf<TSchema>>>
): UseAppForm<TSchema, TResult> {
    const [is500Error, setIs500Error] = useState(false);

    const [request, {
        data,
        error,
        reset,
        isLoading,
        isSuccess,
        isError
    }] = mutation()

    // Hooks
    const serverErrors = useMemo(() => {
        const queryError = error as FetchBaseQueryError

        if (!queryError) return undefined

        if (queryError.status !== 400) {
            setIs500Error(true)
            return {}
        }

        const body = queryError.data as BadRequestBody

        if (!body) {
            setIs500Error(true)
            return {}
        }

        return Object.entries(body.errors).reduce((acc, [key, value]) => {
            return {
                [key]: {
                    types: {
                        type: "server",
                        message: value
                    }
                },
                ...acc
            }
        }, {})
    }, [])

    const form = useForm<z.infer<TSchema>>({
        values: initData,
        errors: serverErrors,
        resolver: zodResolver(validator)
    })

    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault()

        reset()

        try {
            form.handleSubmit(
                request,
                (e) => {
                    console.log(e)
                    if (onError) onError(e)
                }
            )(e)
        } catch (e) {
            console.log(e)
        }
    }
    
    const formReset = () => {
        reset()
        form.reset()
    }
    
    console.log(error)

    return {
        form,
        formReset,
        onFormSubmit,
        is500Error,
        isLoading,
        isSuccess,
        isError,
        result: data
    }
}