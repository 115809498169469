import {DetailedHTMLProps, FC, HTMLAttributes, useMemo} from "react";

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    name: string
    className?: string
    type?: "regular" | "outlined" | "round" | "sharp" | "two-tone"
}

/**
 * @deprecated
 * @param name
 * @param className
 * @param type
 * @param props
 * @constructor
 */
const MaterialIcon: FC<IProps> = ({name, className, type, ...props}) => {
    const classes = useMemo(() => {
        const base = className ? `material-icon ${className}` : `material-icon`
        const modifier = `material-icon--${type ?? "regular"}`

        return `${base} ${modifier}`
    }, [className, type])

    return (
        <span className={classes} {...props}>{name}</span>
    )
}

export default MaterialIcon