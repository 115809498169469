import React, {FC, useMemo, useRef} from "react";
import {useTheme} from "../../../../contexts/ThemeContext";
import {useNavigate, useSearchParams} from "react-router-dom";
import Pane from "../../../../components/Pane/Pane";
import {useGetPaginatedVdcsQuery} from "../../redux/vdcsApi";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import FilledButton from "../../../../components/Buttons/FilledButton/FilledButton";
import MaterialIcon from "../../../../components/MaterialIcon/MaterialIcon";
import Table from "../../../../components/Table/Table";
import {Vdc} from "../../models/Vdc";
import {RouteDefinitions} from "../../../../app/routes/RouteDefinitions";
import StatusChip from "../../../../components/StatusChip/StatusChip";
import {useAppSelector} from "../../../../stores/redux/hooks/useSelector";
import {MediaQueries} from "../../../../utils/MediaQueries";
import FAB from "../../../../components/Buttons/FAB/FAB";

const VdcsPage: FC = () => {
    document.title = "Private Cloud"

    const {getThemeClassName} = useTheme()

    const containerRef = useRef<HTMLDivElement>(null)

    const baseClassName = "vdcs-page"

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const pageSize = 10
    const page = useMemo(() => parseInt(searchParams.get("page") ?? "0"), [searchParams])

    const customerId = useAppSelector(state => state.auth.userInfo?.profile.customerId)

    const {
        data,
        isLoading,
        isFetching,
        isError
    } = useGetPaginatedVdcsQuery({
        customerId: customerId ? customerId : "",
        page,
        pageSize
    }, {
        skip: !customerId
    })

    console.log(customerId)

    return (
        <div
            className={`${baseClassName} ${getThemeClassName(baseClassName)} page-container`}
            ref={containerRef}
        >
            <Pane disableIfAccountNotComplete isLoading={isFetching} title="Private Cloud">
                {
                    !MediaQueries.medium() &&
                    <div className={`${baseClassName}__table-options`}>
                        <PaneHeader title="Private Cloud"/>

                        <FilledButton
                            color="blue"
                            textType="icon-text"
                            className={`${baseClassName}__create-vdc-button`}
                            onClick={() => navigate("/vdcs/create")}
                        >
                            <MaterialIcon name="add"/>
                            Create
                        </FilledButton>
                    </div>
                }

                <Table<Vdc>
                    className={`${baseClassName}__table`}
                    columns={[
                        {
                            name: 'Name',
                            render: (vdc) => <p>{vdc.name}</p>,
                            className: `${baseClassName}__name`
                        },
                        {
                            name: 'Status',
                            render: (vdc) => <StatusChip status={vdc.status}/>,
                            className: `${baseClassName}__status`
                        },
                        {
                            name: 'Created at',
                            render: (vdc) => {
                                let date = new Date(vdc.createdAt)

                                return (
                                    <p>{date.getDate()}-{date.getMonth()}-{date.getFullYear()}</p>
                                )
                            },
                            className: `${baseClassName}__createdAt`,
                            // showOn: false
                        }
                    ]}
                    items={data}
                    page={page}
                    pageSize={pageSize}
                    totalItems={data?.totalItems ?? 0}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    isError={isError}
                    onItemClick={(item) => navigate(RouteDefinitions.vdcs.path + "/" + item.id)}
                />

                {
                    data && MediaQueries.medium() &&
                    <FAB className={`${baseClassName}__create-fab`}
                         icon={"add"}
                         onClick={() => navigate("/vdcs/create")}
                    />
                }
            </Pane>
        </div>
    )
}

export default VdcsPage