import React, {FC, useEffect} from "react";
import Pane from "../../../../components/Pane/Pane";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useGetCustomerInvoiceQuery} from "../../redux/invoicesApi";
import {useAppSelector} from "../../../../stores/redux/hooks/useSelector";
import Loader from "../../../../components/Loader/Loader";
import {useTheme} from "../../../../contexts/ThemeContext";
import MaterialIcon from "../../../../components/MaterialIcon/MaterialIcon";
import {RouteDefinitions} from "../../../../app/routes/RouteDefinitions";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";
import FilledButton from "../../../../components/Buttons/FilledButton/FilledButton";

const InvoicePage: FC = () => {
    const {id} = useParams()
    const {getThemeClassName, theme} = useTheme()
    const navigate = useNavigate()
    const customerId = useAppSelector(state => state.auth.userInfo?.profile.customerId)

    const {data, isLoading} = useGetCustomerInvoiceQuery({
        customerId: customerId ?? "", id: id ?? ""
    }, {
        skip: !customerId || !id
    })

    const baseClassName = "invoice-page"

    const onBackClick = () => {
        navigate(RouteDefinitions.billing.navigationLink!)
    }

    useEffect(() => {
        if (data?.number) {
            document.title = `Invoice #${data.number}`
        } else {
            document.title = `Invoice loading`
        }
    }, [data?.number]);

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <Pane title={"Invoices"}>
                <div className={`${baseClassName}__content`}>
                    {
                        (isLoading || !data)
                            ? (
                                <>
                                    <PaneHeader backButton onBackClick={onBackClick}/>

                                    <div className={`${baseClassName}__loader`}>
                                        <Loader/>
                                    </div>
                                </>
                            )
                            : (
                                <>
                                    <PaneHeader title={`Invoice #${data.number}`} backButton onBackClick={onBackClick}>
                                        {
                                            data.paid ? (
                                                <div
                                                    className={`${baseClassName}__status ${baseClassName}__status--paid`}>
                                                    <MaterialIcon name={"attach_money"}/>

                                                    <p>Paid</p>
                                                </div>
                                            ) : (
                                                <div
                                                    className={`${baseClassName}__status ${baseClassName}__status--unpaid`}>
                                                    <MaterialIcon name={"money_off"}/>

                                                    <p>Unpaid</p>
                                                </div>
                                            )
                                        }

                                        <div className={`${baseClassName}__header`}>
                                            <TextButton color={"blue"} textType={"icon-only"}>
                                                <MaterialIcon name={"download"}/>
                                            </TextButton>

                                            <TextButton color={theme === "dark" ? "grey" : "darker-grey"} textType={"icon-only"}>
                                                <MaterialIcon name={"open_in_new"}/>
                                            </TextButton>
                                        </div>
                                    </PaneHeader>

                                    <div className={`${baseClassName}__table`}>
                                        <div className={`${baseClassName}__table-header`}>
                                            <div className={`
                                                ${baseClassName}__table-column-header
                                                ${baseClassName}__table-name
                                            `}>
                                                Name
                                            </div>
                                            <div className={`
                                                ${baseClassName}__table-column-header
                                                ${baseClassName}__table-price
                                            `}>
                                                Price
                                            </div>
                                            <div className={`
                                                ${baseClassName}__table-column-header
                                                ${baseClassName}__table-quantity
                                            `}>
                                                Quantity
                                            </div>
                                            <div className={`
                                                ${baseClassName}__table-column-header
                                                ${baseClassName}__table-unit
                                            `}>
                                                Unit
                                            </div>
                                            <div className={`
                                                ${baseClassName}__table-column-header
                                                ${baseClassName}__table-subtotal
                                            `}>
                                                Subtotal
                                            </div>
                                            <div className={`
                                                ${baseClassName}__table-column-header 
                                                ${baseClassName}__table-tax
                                            `}>
                                                Tax rate
                                            </div>
                                            <div className={`
                                                ${baseClassName}__table-column-header 
                                                ${baseClassName}__table-total
                                            `}>
                                                Total
                                            </div>
                                        </div>

                                        <div className={`${baseClassName}__table-rows`}>
                                            {data.items.map((item, index) => (
                                                <div className={`${baseClassName}__table-row`}
                                                     key={`invoice-item-${index}`}>
                                                    <div className={`${baseClassName}__table-name`}>{item.name}</div>
                                                    <div
                                                        className={`${baseClassName}__table-price`}>€ {Math.round(item.price * 100) / 100}</div>
                                                    <div className={`${baseClassName}__table-quantity`}>
                                                        {item.quantity}
                                                    </div>
                                                    <div className={`${baseClassName}__table-unit`}>
                                                        {item.unit}
                                                    </div>
                                                    <div className={`${baseClassName}__table-subtotal`}>
                                                        € {((item.price * item.quantity) * (100 - item.tax) / 100).toFixed(2)}
                                                    </div>
                                                    <div className={`${baseClassName}__table-tax`}>{item.tax.toFixed(2)}%
                                                    </div>
                                                    <div className={`${baseClassName}__table-total`}>
                                                        € {(item.price * item.quantity).toFixed(2)}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className={`${baseClassName}__total`}>
                                        <div className={`${baseClassName}__total-line`}>
                                                <p>TOTAL</p>
                                                <p className={`${baseClassName}__total-amount`}>€ {data.total.toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${baseClassName}__footer`}>
                                        {
                                            !data.paid && (
                                                <FilledButton color={"blue"} textType={"icon-text"}>
                                                    <MaterialIcon name={"wallet"}/> Pay
                                                </FilledButton>
                                            )
                                        }
                                    </div>
                                </>
                            )
                    }
                </div>
            </Pane>
        </div>
    )
}

export default InvoicePage