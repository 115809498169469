export class DateHelper {
    static getDateString(date: Date, separator: string = "/") {
        return `${date.getDate()}${separator}${date.getMonth()}${separator}${date.getFullYear()}`
    }

    static daysUntil(date: Date): number {
        const currentDate = new Date();

        const difference = date.getTime() - currentDate.getTime();

        return Math.ceil(difference / (1000 * 60 * 60 * 24));
    }
}