import React, {FC} from "react";
import Typo from "../../../../../../../../components/Typo/Typo";
import MaterialSymbol from "../../../../../../../../components/MaterialSymbol/MaterialSymbol";
import {useTheme} from "../../../../../../../../contexts/ThemeContext";
import FilledButton from "../../../../../../../../components/Buttons/FilledButton/FilledButton";
import {useGetCustomerQuery, useStartBankVerificationMutation} from "../../../../../../../Customers/redux/customersApi";
import {useAppSelector} from "../../../../../../../../stores/redux/hooks/useSelector";

interface IProps {

}

const BankAccountVerification: FC<IProps> = () => {
    const baseClassName = "bank-account-verification"

    const {getThemeClassName} = useTheme()

    const [request] = useStartBankVerificationMutation()
    const customerId = useAppSelector(state => state.auth.userInfo?.profile.customerId)

    const {data} = useGetCustomerQuery(customerId ?? "", {skip: !customerId})

    const startBankVerification = () => {
        if (customerId) {
            request({id: customerId})
                .unwrap()
                .then(response => {
                    window.open(response.paymentUrl)
                })
        }
    }

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <Typo.Title className={`${baseClassName}__title`}>Bank account</Typo.Title>

            {
                !data?.isBankAccountVerified ?
                <>
                    <Typo.Body>
                        To prevent abuse we ask you to pay €0,01 so we are able to identify our
                        customers.
                    </Typo.Body>

                    <FilledButton color={"blue"} textType={"icon-text"} onClick={startBankVerification}>
                        <MaterialSymbol name={"open_in_new"}/>
                        Start
                    </FilledButton>
                </>
                    :
                    <>
                        <Typo.Body className={`${baseClassName}__verified`}><MaterialSymbol name={"check_circle"}/> Verified</Typo.Body>
                    </>
            }
        </div>
    )
}

export default BankAccountVerification