import {useTheme} from "../../../../contexts/ThemeContext";
import {useSearchParams} from "react-router-dom";
import React, {useMemo} from "react";
import {useCustomerId} from "../../../Auth/hooks/useCustomerId";
import {useGetDomainNamesByCustomerIdQuery} from "../../redux/domainNamesApi";
import {DomainName} from "../../models/DomainName";
import Table from "../../../../components/Table/Table";

const DomainNamesTab = () => {
    const baseClassName = "domain-names-tab"
    
    const {getThemeClassName} = useTheme()
    
    const [searchParams] = useSearchParams()
    
    const pageSize = 10
    const page = useMemo(() => parseInt(searchParams.get("page") ?? "0"), [searchParams])
    
    const customerId = useCustomerId()
    
    const {
        data,
        isLoading,
        isFetching,
        isError
    } = useGetDomainNamesByCustomerIdQuery({
        customerId: customerId ?? "",
        page: page,
        pageSize: pageSize
    }, {
        skip: !customerId
    })
    
    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <Table<DomainName>
                className={`${baseClassName}__table`}
                columns={[
                    {
                        name: "Second-level domain (SLD)",
                        className: `${baseClassName}__sld`,
                        render: (item) => <p>{item.sld}</p>
                    },
                    {
                        name: "Top-level domain (TLD)",
                        className: `${baseClassName}__tld`,
                        render: (item) => <p>.{item.tld}</p>
                    },
                    {
                        name: "Registrant",
                        className: `${baseClassName}__registrant`,
                        render: (item) => <p>{item.registrantAlias}</p>
                    }
                ]}
                onItemClick={() => {}}
                page={page}
                pageSize={pageSize}
                totalItems={data?.totalItems ?? 0}
                isLoading={isLoading}
                isFetching={isFetching}
                isError={isError}
                items={data}
            />
        </div>
    )
}

export default DomainNamesTab