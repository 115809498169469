import {LiveCall} from "./LiveCall";
import {pathToRegexp, Key} from "path-to-regexp";
import {AppDispatch} from "../../../stores/redux/types/AppDispatch";

export type RouteHandler = (
    dispatch: AppDispatch,
    method: "created" | "deleted" | "updated",
    pathParams: Record<string, string>,
    body?: string,
) => void

class LiveHandler {
    private routes: Record<string, RouteHandler> = {}

    public handleCall(data: string, dispatch: AppDispatch) {
        let call: LiveCall = JSON.parse(data) as LiveCall
        let {path, method, body} = call

        for (const routePath in this.routes) {
            const keys: Key[] = []
            const regexp = pathToRegexp(routePath, keys)
            const match = regexp.exec(path)

            if (match) {
                const params: Record<string, string> = {}
                keys.forEach((key, index) => params[key.name] = match[index + 1])

                let listener = this.routes[routePath];

                for (let i = 0; i < listener.length; i++) {
                    listener(dispatch, method, params, body);
                }

                return;
            }
        }

        console.info(`No routes found for path ${path}`)
    }

    public addListener(path: string, listener: RouteHandler) {
        this.routes[path] = listener
    }
}

export const liveHandler = new LiveHandler()