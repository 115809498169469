import React, {FC, useEffect, useState} from "react";
import Pane from "../../../../components/Pane/Pane";
import {useTheme} from "../../../../contexts/ThemeContext";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import {CreateVdcRequest, CreateVdcRequestSchema} from "../../models/CreateVdcRequest";
import {useNavigate} from "react-router-dom";
import {RouteDefinitions} from "../../../../app/routes/RouteDefinitions";
import VdcForm from "../../components/VdcForm/VdcForm";
import {useAppForm} from "../../../../hooks/useAppForm";
import {useAuth} from "../../../Auth/slices/authSlice";
import {FormProvider} from "react-hook-form";
import {useCreateVdcMutation} from "../../redux/vdcsApi";
import {MediaQueries} from "../../../../utils/MediaQueries";

interface IProps {

}

const CreateVdcPage: FC<IProps> = () => {
    const baseClassName = "create-vdc-page"
    const {getThemeClassName} = useTheme()

    const navigate = useNavigate()
    const {userInfo} = useAuth()

    const [initData] = useState<CreateVdcRequest>({
        customerId: userInfo?.profile.customerId ?? "",
        name: "",
        cpuLimit: 2,
        memoryLimit: 4,
        storageLimit: 128,
        ipLeases: 2
    })

    const {
        form,
        onFormSubmit,
        isLoading,
        isSuccess,
        isError,
        result
    } = useAppForm(
        initData,
        CreateVdcRequestSchema,
        useCreateVdcMutation
    )

    useEffect(() => {
        if (isSuccess && result) navigate(`${RouteDefinitions.vdcs.path}/${result?.id}`)
    }, [isSuccess, isError, navigate, result]);

    return (
        <div className={`
            ${baseClassName}
            ${getThemeClassName(baseClassName)}
        `}>
            <Pane
                className={`${baseClassName}__pane`}
                disableIfAccountNotComplete
                showDisabledText={true}
                isLoading={isLoading}
                title="Private Cloud"
                showBackButton={MediaQueries.medium()}
            >
                <PaneHeader
                    backButton={!MediaQueries.medium()}
                    title={"Create"}
                    parentTitle={!MediaQueries.medium() && "Private Cloud"}
                />

                <FormProvider {...form}>
                    <VdcForm
                        onSubmit={onFormSubmit}
                        isLoading={isLoading}
                        onCancel={() => navigate(`${RouteDefinitions.vdcs.path}`)}
                        name={initData.name}
                    />
                </FormProvider>
            </Pane>
        </div>
    )
}

export default CreateVdcPage